// Copyright 2021 Merit International Inc. All Rights Reserved

import { Body } from "../Text/Body/Body";
import { StyleSheet, View } from "react-native";
import { getTestProps } from "../../utils/testProps";
import { useTheme } from "../../theme/useTheme";
import type { BodyProps } from "../Text";
import type { ComponentTestProps } from "../../utils/testProps";
import type { StyleProp, ViewStyle } from "react-native";

export type LabeledTextProps = ComponentTestProps & {
  readonly label: string;
  readonly labelProps?: Exclude<BodyProps, keyof ComponentTestProps | "style">;
  readonly text: string;
  readonly textProps?: Exclude<BodyProps, keyof ComponentTestProps | "style">;
  readonly style?: StyleProp<ViewStyle>;
};

export const LabeledText = ({
  label,
  labelProps,
  style,
  testID,
  testProps,
  testUniqueID,
  text,
  textProps,
}: LabeledTextProps) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create({
    label: {
      marginBottom: theme.spacing.xs,
    },
  });

  return (
    <View
      style={style}
      {...getTestProps(
        { componentName: "LabeledText", testID, testProps, testUniqueID },
        { componentName: "LabeledText" }
      )}
    >
      <Body style={styles.label} {...labelProps}>
        {label}
      </Body>
      <Body {...textProps} size="l">
        {text}
      </Body>
    </View>
  );
};
