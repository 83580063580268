// Copyright 2021 Merit International Inc. All Rights Reserved

import { BaseText, resolveTextStyle } from "../Base";
import { StyleSheet } from "react-native";
import { pickComponentTestProps } from "../../../utils/testProps";
import { useTheme } from "../../../theme/useTheme";
import type { BaseTextProps } from "../Base";

export type BodyProps = BaseTextProps & { readonly size?: "l" | "s" | "xl" };

export const Body = ({
  children,
  size = "s",
  style,
  testID,
  testProps,
  testUniqueID,
  ...rest
}: BodyProps) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    bodyS: {
      fontSize: 14,
      lineHeight: 20,
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    bodyL: {
      fontSize: 16,
      lineHeight: 24,
    },
    bodyXl: {
      fontSize: 42,
      fontWeight: theme.fontWeights.semiBold,
      lineHeight: 44,
    },
  });

  const componentTestProps = pickComponentTestProps({
    testID,
    testProps,
    testUniqueID,
  });

  const baseTextStyle = resolveTextStyle(theme.fonts.normal, [
    size === "l" && styles.bodyL,
    size === "s" && styles.bodyS,
    size === "xl" && styles.bodyXl,
    style,
  ]);

  return (
    <BaseText style={baseTextStyle} {...componentTestProps} {...rest}>
      {children}
    </BaseText>
  );
};
