// Copyright 2022 Merit International Inc. All Rights Reserved

import "react-datepicker/dist/react-datepicker.css";
// eslint-disable-next-line sort-imports-es6-autofix/sort-imports-es6
import "./DatePicker.css";
import { Body } from "../Text";
import { Helpers } from "@merit/frontend-utils";
import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import { getTestProps } from "../../utils/testProps";
import ReactDatePicker, { getDefaultLocale } from "react-datepicker";
import chevronDown from "../../../assets/chevron-down-icon.png";
import type { DateTimePickerProps } from "./types";

const { Some } = Helpers;

const styles = StyleSheet.create({
  header: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: "6px",
    paddingLeft: "12px",
    paddingRight: "12px",
  },
});

export const DateTimePicker = ({
  disabled,
  maxDate,
  minDate,
  onBlur,
  onChange,
  onFocus,
  showTimeSelect,
  testID,
  testProps,
  testUniqueID,
  value,
}: DateTimePickerProps) => (
  <View
    style={{ position: "relative" }}
    {...getTestProps({
      componentName: "DateTimePicker",
      testID,
      testProps,
      testUniqueID,
    })}
  >
    <ReactDatePicker<"", false>
      className={disabled === true ? "datePicker-input-disabled" : "datePicker-input"}
      dateFormat={Some(showTimeSelect) ? "MM / dd / yyyy h:mm aa" : "MM / dd / yyyy"}
      disabled={disabled}
      formatWeekDay={nameOfDay => nameOfDay.substring(0, 3)}
      locale={getDefaultLocale()}
      maxDate={maxDate}
      minDate={minDate}
      onBlur={onBlur}
      onChange={newDate => {
        onChange(newDate?.toString() ?? undefined);
      }}
      onClickOutside={onBlur}
      onFocus={onFocus}
      onSelect={onBlur}
      renderCustomHeader={({ date: headerDate, decreaseMonth, increaseMonth }) => (
        <View style={styles.header}>
          <TouchableOpacity onPress={decreaseMonth}>
            <Image accessible source={chevronDown} style={{ height: 20, width: 20 }} />
          </TouchableOpacity>

          <Body>{`${headerDate.toLocaleString("default", {
            month: "long",
          })} ${headerDate.getFullYear()}`}</Body>

          <TouchableOpacity onPress={increaseMonth}>
            <Image accessible source={chevronDown} style={{ height: 20, width: 20 }} />
          </TouchableOpacity>
        </View>
      )}
      selected={value}
      showPopperArrow={false}
      showTimeSelect={showTimeSelect}
    />
  </View>
);
