// Copyright 2021 Merit International Inc. All Rights Reserved

import { Image } from "react-native";
import { getTestProps } from "../../utils/testProps";
import React from "react";
import meritCheckinDark from "../../assets/images/merit-checkin-dark.png"; // 492 x 64
import meritCheckinLight from "../../assets/images/merit-checkin-light.png"; // 492 x 64
import type { ComponentTestProps } from "../../utils/testProps";

export type MeritCheckinLogoProps = ComponentTestProps & {
  readonly color?: "dark" | "light";
  readonly height?: number;
};

export const MeritCheckinLogo = ({
  color = "light",
  height = 64,
  testID,
  testProps,
  testUniqueID,
}: MeritCheckinLogoProps) => (
  <Image
    accessibilityLabel="Merit Check-in Logo"
    accessible
    resizeMode="contain"
    source={color === "light" ? meritCheckinLight : meritCheckinDark}
    style={{ height, width: height * 7.6875 }}
    {...getTestProps({ componentName: "MeritCheckinLogo", testID, testProps, testUniqueID })}
  />
);
