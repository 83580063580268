// Copyright 2022 Merit International Inc. All Rights Reserved

import { Helpers } from "@merit/frontend-utils";
import { StyleSheet, View } from "react-native";
import { getTestProps } from "../../utils/testProps";
import type { TableRowProps, TableRow as TableRowType } from "./types";

const { Some } = Helpers;

const styles = StyleSheet.create({
  row: {
    alignItems: "center",
    borderBottomColor: "rgba(0,0,0,0.1)",
    borderBottomWidth: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
});

export function TableRow<T extends TableRowType>({
  autoWidth,
  columns,
  render,
  row,
  testID,
  testProps,
  testUniqueID,
}: TableRowProps<T>) {
  return (
    <View
      key={row.id}
      style={styles.row}
      {...getTestProps(
        {
          componentName: "TableRow",
          testID,
          testProps,
          testUniqueID,
        },
        {
          componentName: "TableRow",
        }
      )}
    >
      {columns.map(column => (
        <View
          key={`${row.id}-${column.key}`}
          style={
            column.width === "flex"
              ? {
                  flex: 1,
                }
              : {
                  width: column.width === "auto" ? autoWidth : column.width,
                }
          }
          {...getTestProps(
            {
              componentName: "TableItem",
              testID,
              testProps: Some(testProps)
                ? {
                    ...testProps,
                    elementId: `${row.id}`,
                    elementName: `${column.key}${testProps.elementName}`,
                  }
                : testProps,
              testUniqueID,
            },
            {
              componentName: "TableItem",
            }
          )}
        >
          {render(row, column.key)}
        </View>
      ))}
    </View>
  );
}
