// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body } from "../Text/Body/Body";
import { Icon } from "../Icon";
import { Pressable, StyleSheet, View } from "react-native";
import { useHover } from "react-native-web-hooks";
import { useRef, useState } from "react";
import { useTheme } from "../../theme/useTheme";
import type { MenuItemPressable } from "./types";
import type { StyleProp, TextStyle, ViewStyle } from "react-native";

type MenuItemProps = {
  readonly menuItem: MenuItemPressable;
  readonly style?: StyleProp<ViewStyle>;
};

const MENU_ITEM_ICON_CONTAINER_SIZE = 20; // TODO: we should have a standard size for "regular" icons for consistency

export const DropdownMenuPressable = ({ menuItem, style }: MenuItemProps) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);
  const [isPressedIn, setIsPressedIn] = useState(false);

  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly hovered: ViewStyle;
    readonly iconContainer: ViewStyle;
    readonly iconContainerDisabled: ViewStyle;
    readonly iconContainerLeft: ViewStyle;
    readonly labelDisabled: TextStyle;
    readonly labelTypeDestructive: TextStyle;
    readonly pressedIn: ViewStyle;
    readonly text: TextStyle;
    readonly textWithIconRight: TextStyle;
  }>({
    container: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: theme.spacing.l,
      paddingVertical: theme.spacing.s,
    },
    hovered: {
      backgroundColor: theme.colors.background.default,
    },
    iconContainer: {
      height: MENU_ITEM_ICON_CONTAINER_SIZE,
      width: MENU_ITEM_ICON_CONTAINER_SIZE,
    },
    // until we consistently have all icon variants available for every icon in use, use half opacity as an interim disabled state
    iconContainerDisabled: {
      opacity: 0.5,
    },
    iconContainerLeft: {
      marginRight: theme.spacing.s,
    },
    labelDisabled: {
      color: theme.colors.text.disabled,
    },
    labelTypeDestructive: {
      color: theme.colors.text.alert.critical,
    },
    pressedIn: {
      backgroundColor: theme.colors.brand.turquiose,
    },
    text: {
      ...theme.fontSizes.m,
      flex: 1,
    },
    textWithIconRight: {
      marginRight: theme.spacing.s,
    },
  });

  return (
    <Pressable
      disabled={menuItem.disabled}
      key={menuItem.id}
      onPress={() => {
        menuItem.onPress();
      }}
      onPressIn={() => {
        setIsPressedIn(true);
      }}
      onPressOut={() => {
        setIsPressedIn(false);
      }}
      ref={ref}
      style={[
        styles.container,
        style,
        isHovered && styles.hovered,
        isPressedIn && styles.pressedIn,
      ]}
    >
      {menuItem.iconLeft !== undefined && (
        <View
          style={[
            styles.iconContainer,
            styles.iconContainerLeft,
            menuItem.disabled === true && styles.iconContainerDisabled,
          ]}
        >
          <Icon name={menuItem.iconLeft} />
        </View>
      )}
      <Body
        style={[
          styles.text,
          menuItem.pressableKind === "destructive" && styles.labelTypeDestructive,
          Boolean(menuItem.disabled) && styles.labelDisabled,
          menuItem.iconRight !== undefined && styles.textWithIconRight,
        ]}
      >
        {menuItem.text}
      </Body>
      {menuItem.iconRight !== undefined && (
        <View
          style={[styles.iconContainer, menuItem.disabled === true && styles.iconContainerDisabled]}
        >
          <Icon name={menuItem.iconRight} />
        </View>
      )}
    </Pressable>
  );
};
