// Copyright 2021 Merit International Inc. All Rights Reserved

import { Dimensions, StyleSheet, View } from "react-native";
import { TableHeading } from "./TableHeading";
import { TableRow } from "./TableRow";
import { getTestProps } from "../../utils/testProps";
import { sortTableData, useSort } from "./sort";
import type { TableProps, TableRow as TableRowType } from "./types";

/*
  Merit-themed type-safe table

  The order of the columns is determined by the order of the columns array
*/

const MIN_COLUMN_WIDTH = 135;

const styles = StyleSheet.create({
  baseRow: {
    alignItems: "center",
    borderBottomColor: "rgba(0,0,0,0.1)",
    borderBottomWidth: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  tableContainer: {
    backgroundColor: "white",
  },
});

export function Table<T extends TableRowType>({
  columns,
  data,
  defaultSort = {
    direction: "Ascending",
    key: "id",
  },
  emptyComponent,
  onPressHeader,
  testProps,
  testID,
  testUniqueID,
  render,
  shouldSkipSorting = false,
  showHeaders = true,
}: TableProps<T>) {
  const [currentSorting, handleChangeSort] = useSort<T>(defaultSort);

  // No columns - if there are dynamically generated columns this will just return
  // an empty view while they are being generated rather than crashing
  if (columns.length === 0) {
    return <View />;
  }

  const autoWidth = Math.max(MIN_COLUMN_WIDTH, Dimensions.get("screen").width / columns.length);

  return (
    <View
      style={styles.tableContainer}
      {...getTestProps(
        { componentName: "Table", testID, testProps, testUniqueID },
        { componentName: "Table" }
      )}
    >
      {showHeaders ? (
        <View style={styles.baseRow}>
          {columns.map(col => (
            <TableHeading
              autoWidth={autoWidth}
              column={col}
              currentSorting={currentSorting}
              key={col.key}
              onPressHeader={(key: keyof T) => {
                if (shouldSkipSorting) {
                  onPressHeader?.(key);
                } else {
                  handleChangeSort(key);
                }
              }}
              testProps={testProps}
            />
          ))}
        </View>
      ) : null}
      <>
        {data === undefined || data.length === 0
          ? emptyComponent
          : (shouldSkipSorting ? data : sortTableData(data, currentSorting)).map(row => (
              <TableRow
                autoWidth={autoWidth}
                columns={columns}
                key={row.id}
                render={render}
                row={row}
                testProps={testProps}
              />
            ))}
      </>
    </View>
  );
}

export type { TableProps, TableRow as TableRowType, TableColumn } from "./types";
