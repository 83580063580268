// Copyright 2022 Merit International Inc. All Rights Reserved

import { persist } from "zustand/middleware";
import AsyncStorage from "@react-native-async-storage/async-storage";
import create from "zustand";
import type { PersistOptions } from "zustand/middleware";
import type { StateCreator } from "zustand";
import type { ThemeName } from "../theme/themes";

export type ThemeState = {
  readonly setTheme: (newTheme: ThemeName) => void;
  readonly theme: ThemeName;
};

// HACK: workaround for https://github.com/pmndrs/zustand/issues/650 in recent zustand versions
type TypedPersist = (
  config: StateCreator<ThemeState>,
  options: PersistOptions<ThemeState>
) => StateCreator<ThemeState>;

export const useThemeStore = create<ThemeState>(
  (persist as TypedPersist)(
    set => ({
      setTheme: (newTheme: ThemeName) => {
        set(() => ({ theme: newTheme }));
      },
      theme: "LIGHT", // Theme on initial launch
    }),
    {
      getStorage: () => AsyncStorage,
      name: "theme-storage",
    }
  )
);
