// Copyright 2021 Merit International Inc. All Rights Reserved

import { StyleSheet, View } from "react-native";
import { TableHeading } from "./TableHeading.web";
import { TableRow } from "./TableRow.web";
import { getTestProps } from "../../utils/testProps";
import { sortTableData, useSort } from "./sort";
import { useState } from "react";
import type { TableProps, TableRow as TableRowType } from "./types";

/*
  Merit-themed type-safe table

  The order of the columns is determined by the order of the columns array
*/

const MIN_COLUMN_WIDTH = 100;

const styles = StyleSheet.create({
  tableContainer: {
    backgroundColor: "white",
    flex: 1,
  },
});

export function Table<T extends TableRowType>({
  columns,
  data,
  defaultSort = {
    direction: "Ascending",
    key: "id",
  },
  emptyComponent,
  testProps,
  testID,
  testUniqueID,
  onPressHeader,
  render,
  shouldSkipSorting = false,
  showHeaders = true,
}: TableProps<T>) {
  const [tableWidth, setTableWidth] = useState<number>();
  const [currentSorting, handleChangeSort] = useSort<T>(defaultSort);

  // No columns - if there are dynamically generated columns this will just return
  // an empty view while they are being generated rather than crashing
  if (columns.length === 0) {
    return <View />;
  }

  const autoWidth = Math.max(
    MIN_COLUMN_WIDTH,
    tableWidth === undefined ? 10 : tableWidth / columns.length
  );

  return (
    <View
      onLayout={e => {
        setTableWidth(e.nativeEvent.layout.width);
      }}
      style={styles.tableContainer}
      {...getTestProps(
        {
          componentName: "Table",
          testID,
          testProps,
          testUniqueID,
        },
        {
          componentName: "Table",
        }
      )}
    >
      <table
        style={{
          border: 0,
          borderCollapse: "collapse",
          borderSpacing: 0,
          margin: 0,
          padding: 0,
        }}
      >
        {showHeaders ? (
          <thead>
            <tr>
              {columns.map((column, index) => (
                <TableHeading
                  autoWidth={autoWidth}
                  column={column}
                  currentSorting={currentSorting}
                  isFirst={index === 0}
                  isLast={index === columns.length}
                  key={column.key}
                  onPressHeader={(key: keyof T) => {
                    if (shouldSkipSorting) {
                      onPressHeader?.(key);
                    } else {
                      handleChangeSort(key);
                    }
                  }}
                  testProps={testProps}
                />
              ))}
            </tr>
          </thead>
        ) : null}
        <tbody>
          {data === undefined || data.length === 0 ? (
            <td colSpan={columns.length}>{emptyComponent}</td>
          ) : (
            (shouldSkipSorting ? data : sortTableData(data, currentSorting)).map(row => (
              <TableRow
                autoWidth={autoWidth}
                columns={columns}
                key={row.id}
                render={render}
                row={row}
                testProps={testProps}
              />
            ))
          )}
        </tbody>
      </table>
    </View>
  );
}
