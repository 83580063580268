// Copyright 2022 Merit International Inc. All Rights Reserved

import { themeNameIsValid, themes } from "./themes";
import { useThemeStore } from "../store/themeStore";
import type { ThemeName } from "./themes";

function protectedSetTheme(setter: (newTheme: ThemeName) => void) {
  return (theme: ThemeName): void => {
    if (themeNameIsValid(theme)) {
      setter(theme);
    }
  };
}

export const useTheme = () => {
  // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
  const theme = useThemeStore(state => state.theme);
  const { setTheme } = useThemeStore();

  return {
    setTheme: protectedSetTheme(setTheme),
    theme: themes[theme],
    themeName: theme,
  } as const;
};
