// Copyright 2021 Merit International Inc. All Rights Reserved

import { Image } from "react-native";
import { getTestProps } from "../../utils/testProps";
import React from "react";
import meritSmileTurquoise from "../../assets/images/merit-smile-turquoise.png";
import type { ComponentTestProps } from "../../utils/testProps";

export type MeritLogoProps = ComponentTestProps & {
  readonly height?: number;
  readonly width?: number;
};

export const MeritLogo = ({
  height = 32,
  testID,
  testProps,
  testUniqueID,
  width = 100,
}: MeritLogoProps) => (
  <Image
    accessibilityLabel="Merit logo"
    accessible
    resizeMode="contain"
    source={meritSmileTurquoise}
    style={{ height, width }}
    {...getTestProps({ componentName: "MeritLogo", testID, testProps, testUniqueID })}
  />
);
