// Copyright 2021 Merit International Inc. All Rights Reserved

import { Helpers } from "@merit/frontend-utils";
import { InternalRadioButton } from "./InternalRadioButton";
import { Platform, View } from "react-native";
import { getTestProps } from "../../utils/testProps";
import React, { useEffect, useState } from "react";
import type { ComponentTestProps } from "../../utils/testProps";

const { Some } = Helpers;

/*
  Wrapper component for radio buttons which maintains internal state to track of which option is selected.
  This component is the only component exported from the index file.
*/

export type RadioOptionType<T> = {
  readonly accessibilityHint?: string;
  readonly accessibilityLabel?: string;
  readonly disabled?: boolean;
  readonly label: string;
  readonly testProps?: Helpers.TestProps;
  readonly value: T;
};

export type RadioButtonGroupProps<T> = ComponentTestProps & {
  readonly accessibilityHint?: string;
  readonly accessibilityLabel?: string;
  readonly disabled?: boolean;
  readonly initSelected?: T;
  readonly onChange: (arg: T) => void;
  readonly options: readonly RadioOptionType<T>[];
  readonly shape?: "checkmark" | "marker";
  readonly size?: "medium" | "small";
  readonly hideLabel?: boolean;
  readonly labelSize?: "l" | "s";
};

export function RadioButtonGroup<T>({
  accessibilityHint,
  accessibilityLabel,
  disabled = false,
  hideLabel = false,
  initSelected,
  labelSize,
  onChange,
  options,
  shape = "marker",
  size = "medium",
  testID,
  testProps,
  testUniqueID,
}: RadioButtonGroupProps<T>) {
  const [selectedVal, setSelectedVal] = useState<T | undefined>(initSelected);

  function setValueAndCallback(val: T) {
    if (!disabled) {
      setSelectedVal(val);
      onChange(val);
    }
  }

  useEffect(() => {
    if (Some(initSelected)) {
      onChange(initSelected);
    }
  }, [initSelected, onChange]);

  const isWeb = Platform.OS === "web";
  const hint = isWeb ? accessibilityHint : undefined;
  const label = isWeb ? accessibilityLabel : undefined;

  return (
    <View
      accessibilityHint={hint}
      accessibilityLabel={label}
      accessibilityRole="radiogroup"
      accessible
      {...getTestProps(
        { componentName: "RadioButtonGroup", testID, testProps, testUniqueID },
        { componentName: "RadioButtonGroup" }
      )}
    >
      {options.map((item, idx) => {
        const key = idx.toString() + item.label;

        return (
          <InternalRadioButton<T>
            disabled={item.disabled ?? disabled}
            hideLabel={hideLabel}
            item={item}
            key={key}
            labelSize={labelSize}
            onChange={setValueAndCallback}
            selected={selectedVal === item.value}
            shape={shape}
            size={size}
            testProps={
              Some(testProps) ? { ...testProps, elementId: String(item.value) } : testProps
            }
          />
        );
      })}
    </View>
  );
}
