// Copyright 2023 Merit International Inc. All Rights Reserved

import { BaseText, resolveTextStyle } from "../Base";
import { StyleSheet } from "react-native";
import { pickComponentTestProps } from "../../../utils/testProps";
import { useTheme } from "../../../theme/useTheme";
import type { BaseTextProps } from "../Base";

export type ButtonProps = BaseTextProps & {
  readonly size?: "extraSmall" | "large" | "medium" | "small";
};

export const Button = ({
  children,
  size = "large",
  style,
  testID,
  testProps,
  testUniqueID,
  ...rest
}: ButtonProps) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    buttonExtraSmall: {
      fontSize: 12,
      fontWeight: theme.fontWeights.normal,
      lineHeight: 16,
    },
    buttonLarge: {
      fontSize: 16,
      fontWeight: theme.fontWeights.semiBold,
      lineHeight: 24,
    },
    buttonSmall: {
      fontSize: 14,
      fontWeight: theme.fontWeights.semiBold,
      lineHeight: 20,
    },
  });

  const componentTestProps = pickComponentTestProps({
    testID,
    testProps,
    testUniqueID,
  });

  const baseTextStyle = resolveTextStyle(theme.fonts.normal, [
    size === "large" && styles.buttonLarge,
    (size === "small" || size === "medium") && styles.buttonSmall,
    size === "extraSmall" && styles.buttonExtraSmall,
    style,
  ]);

  return (
    <BaseText style={baseTextStyle} {...componentTestProps} {...rest}>
      {children}
    </BaseText>
  );
};
