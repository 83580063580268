import { Chevron } from "../Chevron";
import { Colors } from "../../theme/colors";
import { Heading } from "../Text";
import { Pressable, StyleSheet, View } from "react-native";
import { getTestProps } from "../../utils/testProps";
import { useState } from "react";
import { useTheme } from "../../theme/useTheme";
import type { ComponentTestProps } from "../../utils/testProps";
import type { PropsWithChildren, ReactNode } from "react";

type BaseProps = {
  readonly initiallyOpen?: boolean;
};

type PropsWithTitle = BaseProps & { readonly headerTitle: string; readonly type: "default" };

type PropsWithCustomContent = BaseProps & {
  readonly renderHeader: ReactNode;
  readonly type: "custom";
};

export type CollapseProps = ComponentTestProps & (PropsWithCustomContent | PropsWithTitle);

export const Collapse: React.FC<PropsWithChildren<CollapseProps>> = ({
  children,
  initiallyOpen = false,
  testID,
  testProps,
  testUniqueID,
  ...props
}) => {
  const [open, setOpen] = useState(initiallyOpen);
  const { theme } = useTheme();
  const styles = StyleSheet.create({
    body: {
      paddingHorizontal: 40,
      paddingVertical: 16,
    },
    container: {
      ...theme.elevations.depth2,
      backgroundColor: theme.colors.background.white,
      height: "100%",
    },
    header: {
      alignItems: "center",
      flexDirection: "row",
      paddingHorizontal: 40,
      paddingVertical: 13,
    },
    label: {
      color: theme.colors.text.actionButton,
    },
    titleWrapper: {
      paddingLeft: 10,
      width: "100%",
    },
  });

  return (
    <View
      style={styles.container}
      {...getTestProps({
        componentName: "Collapse",
        testID,
        testProps,
        testUniqueID,
      })}
    >
      <View style={styles.header}>
        <Pressable
          onPress={() => {
            setOpen(prevState => !prevState);
          }}
          {...getTestProps(
            {
              componentName: "CollapseHeader",
              testID,
              testProps,
              testUniqueID,
            },
            { componentName: "CollapseHeader" }
          )}
        >
          <Chevron
            color={Colors.neutral800}
            customStyles={{ borderRightWidth: 2, borderTopWidth: 2 }}
            direction={open ? "up" : "down"}
            size={12}
          />
        </Pressable>
        {props.type === "default" ? (
          <View style={styles.titleWrapper}>
            <Heading bold level="3" style={styles.label}>
              {props.headerTitle}
            </Heading>
          </View>
        ) : (
          <View style={styles.titleWrapper}>{props.renderHeader}</View>
        )}
      </View>
      {open && (
        <View
          style={styles.body}
          {...getTestProps(
            {
              componentName: "CollapseContent",
              testID,
              testProps,
              testUniqueID,
            },
            { componentName: "CollapseContent" }
          )}
        >
          {children}
        </View>
      )}
    </View>
  );
};
