// Copyright 2023 Merit International Inc. All Rights Reserved

import { AccessibilityWidget as AccessibilityWidgetDefault } from "./AccessibilityWidget";
import { AccessibilityWidget as AccessibilityWidgetWeb } from "./AccessibilityWidget.web";
import { Platform } from "react-native";

export const AccessibilityWidget = Platform.select({
  default: AccessibilityWidgetDefault,
  web: AccessibilityWidgetWeb,
});
