// Copyright 2022 Merit International Inc. All Rights Reserved

import { Heading } from "../Text";
import { Icon } from "../Icon";
import { Image, Pressable, StyleSheet, View } from "react-native";
import { getTestProps } from "../../utils/testProps";
import { useTheme } from "../../theme/useTheme";
import defaultOrg from "../../assets/images/org-logo.png";
import type { ComponentTestProps } from "../../utils/testProps";

export type OrganizationCardProps = ComponentTestProps & {
  readonly name: string;
  readonly onPress: () => void;
};

export const OrganizationCard = ({
  name,
  onPress,
  testID,
  testProps,
  testUniqueID,
}: OrganizationCardProps) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    card: {
      backgroundColor: theme.colors.background.default,
      borderColor: theme.colors.border.default,
      borderRadius: 4,
      borderWidth: 1,
      paddingHorizontal: 16,
      paddingVertical: 12,
    },
    icon: {
      justifyContent: "center",
      marginRight: 16,
    },
    innerWrapper: {
      alignItems: "center",
      flexDirection: "row",
    },
  });

  return (
    <Pressable
      onPress={onPress}
      style={styles.card}
      {...getTestProps({ componentName: "OrganizationCard", testID, testProps, testUniqueID })}
    >
      <View style={styles.innerWrapper}>
        <View style={styles.icon}>
          <Image source={defaultOrg} style={{ height: 40, resizeMode: "contain", width: 40 }} />
        </View>
        <View style={{ flex: 1, flexGrow: 1 }}>
          <Heading bold level="3">
            {name}
          </Heading>
        </View>
        <View style={{ paddingLeft: 16 }}>
          <Icon name="arrowForwardMediumAction" />
        </View>
      </View>
    </Pressable>
  );
};
