// Copyright 2021 Merit International Inc. All Rights Reserved

import * as popup from "react-native-walkthrough-tooltip";
import { Heading } from "../Text";
import { Helpers } from "@merit/frontend-utils";
import { Image, Platform, Pressable, StatusBar, StyleSheet, View } from "react-native";
import { emptyFunction } from "../../test-utils/emptyFunction";
import { getTestProps } from "../../utils/testProps";
import { useState } from "react";
import { useTheme } from "../../theme";
import type { FCWithChildren } from "../types";
import type { PopoverProps } from "./types";

const { Some } = Helpers;

export const Popover: FCWithChildren<PopoverProps> = ({
  children,
  content,
  imageSource,
  nativePlacement,
  onToggle = emptyFunction,
  testID,
  testUniqueID,
  testProps,
  title,
}) => {
  const [isShown, setIsShown] = useState(false);
  const { theme } = useTheme();

  const handleToggle = () => {
    onToggle(!isShown);
    setIsShown(prevState => !prevState);
  };

  const styles = StyleSheet.create({
    body: {
      lineHeight: theme.fontSizes.m.lineHeight,
      paddingHorizontal: 16,
      paddingVertical: 8,
    },
    container: {
      ...theme.elevations.depth5,
      backgroundColor: theme.colors.background.white,
      borderRadius: 4,
      color: theme.colors.text.actionButton,
      fontSize: theme.fontSizes.m.fontSize,
      zIndex: 999,
    },
    content: {
      maxWidth: 20,
    },
    image: {
      height: 164,
      width: "100%",
    },
    pressable: {
      width: "100%",
    },
    title: {
      borderBottomWidth: 1,
      borderColor: theme.colors.border.subdued,
      paddingHorizontal: 16,
      paddingVertical: 8,
    },
  });

  // eslint-disable-next-line react/no-multi-comp
  const PopoverContent = () => (
    <View style={styles.container}>
      {Some(imageSource) && <Image source={imageSource} style={styles.image} />}
      {Some(title) && (
        <View style={styles.title}>
          <Heading level="4">{title}</Heading>
        </View>
      )}
      <View style={styles.body}>{content}</View>
    </View>
  );

  return (
    <View
      style={styles.content}
      {...getTestProps({
        componentName: "Popover",
        testID,
        testProps,
        testUniqueID,
      })}
    >
      <popup.default
        backgroundColor="transparent"
        content={<PopoverContent />}
        isVisible={isShown}
        placement={nativePlacement}
        topAdjustment={
          Platform.OS === "android" && Some(StatusBar) && Some(StatusBar.currentHeight)
            ? -StatusBar.currentHeight
            : 0
        }
      >
        <Pressable onPress={handleToggle} style={styles.pressable}>
          {children}
        </Pressable>
      </popup.default>
    </View>
  );
};
