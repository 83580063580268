// Copyright 2021 Merit International Inc. All Rights Reserved

import { BaseText } from "../Base";
import { Platform, StyleSheet } from "react-native";
import { useTheme } from "../../../theme/useTheme";
import React from "react";
import type { ComponentTestProps } from "../../../utils/testProps";
import type { ReactNode } from "react";

const styles = StyleSheet.create({
  link: {
    textDecorationLine: "underline",
  },
  linkLarge: {
    fontSize: 16,
    lineWeight: 24,
  },
  linkSmall: {
    fontSize: 14,
    lineWeight: 20,
  },
});

export type LinkProps = ComponentTestProps & {
  readonly capitalize?: boolean;
  readonly center?: boolean;
  readonly children: ReactNode;
  readonly color?: string;
  readonly numberOfLines?: number;
  readonly onPress: () => Promise<void> | void; // Not optional for links
  readonly onTextLayout?: () => void;
  readonly uppercase?: boolean;
  readonly size?: "l" | "s";
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Link = ({ children, size = "s", ...rest }: LinkProps) => {
  const { theme } = useTheme();

  return (
    <BaseText
      {...Platform.select({
        web: {
          accessibilityRole: "link",
        },
      })}
      style={[
        styles.link,
        size === "s" && styles.linkSmall,
        size === "l" && styles.linkLarge,
        { fontFamily: theme.fonts.normal },
      ]}
      {...rest}
    >
      {children}
    </BaseText>
  );
};
