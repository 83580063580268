/* eslint-disable sort-keys-fix/sort-keys-fix */
// Copyright 2022 Merit International Inc. All Rights Reserved.

export const Colors: Record<string, string> = {
  midnight50: "rgba(213,241,255,1)",
  midnight100: "rgba(178,215,227,1)",
  midnight200: "rgba(142,185,200,1)",
  midnight300: "rgba(105,156,174,1)",
  midnight400: "rgba(77,134,153,1)",
  midnight500: "rgba(44,113,134,1)",
  midnight600: "rgba(32,99,118,1)",
  midnight700: "rgba(16,80,96,1)",
  midnight800: "rgba(0,61,76,1)",
  midnight900: "rgba(0,40,53,1)",
  oceanBlue50: "rgba(225,245,254,1)",
  oceanBlue100: "rgba(181,230,251,1)",
  oceanBlue200: "rgba(132,214,247,1)",
  oceanBlue300: "rgba(84,198,239,1)",
  oceanBlue400: "rgba(53,187,232,1)",
  oceanBlue500: "rgba(41,176,224,1)",
  oceanBlue600: "rgba(32,161,204,1)",
  oceanBlue700: "rgba(17,141,177,1)",
  oceanBlue800: "rgba(0,122,152,1)",
  oceanBlue900: "rgba(0,88,107,1)",
  turqoise50: "rgba(222,246,245,1)",
  turqoise100: "rgba(173,233,228,1)",
  turqoise200: "rgba(113,219,210,1)",
  turqoise300: "rgba(0,204,192,1)",
  turqoise400: "rgba(0,191,174,1)",
  turqoise500: "rgba(0,177,158,1)",
  turqoise600: "rgba(0,163,143,1)",
  turqoise700: "rgba(0,146,126,1)",
  turqoise800: "rgba(0,130,110,1)",
  turqoise900: "rgba(0,100,79,1)",
  jade50: "rgba(226,244,236,1)",
  jade100: "rgba(184,227,207,1)",
  jade200: "rgba(136,209,177,1)",
  jade300: "rgba(80,191,147,1)",
  jade400: "rgba(0,178,125,1)",
  jade500: "rgba(0,164,102,1)",
  jade600: "rgba(0,150,92,1)",
  jade700: "rgba(0,132,79,1)",
  jade800: "rgba(0,115,67,1)",
  jade900: "rgba(0,84,45,1)",
  citrine50: "rgba(253,247,224,1)",
  citrine100: "rgba(251,234,178,1)",
  citrine200: "rgba(249,221,128,1)",
  citrine300: "rgba(247,208,76,1)",
  citrine400: "rgba(245,197,37,1)",
  citrine500: "rgba(244,187,0,1)",
  citrine600: "rgba(244,174,0,1)",
  citrine700: "rgba(244,155,0,1)",
  citrine800: "rgba(244,138,0,1)",
  citrine900: "rgba(244,107,0,1)",
  coral50: "rgba(255,237,239,1)",
  coral100: "rgba(255,209,214,1)",
  coral200: "rgba(244,161,160,1)",
  coral300: "rgba(237,125,123,1)",
  coral400: "rgba(248,96,89,1)",
  coral500: "rgba(255,83,64,1)",
  coral600: "rgba(240,73,62,1)",
  coral700: "rgba(221,63,56,1)",
  coral800: "rgba(208,57,49,1)",
  coral900: "rgba(193,47,37,1)",
  neutral10: "rgba(250,251,252,1)",
  neutral20: "rgba(244,245,247,1)",
  neutral30: "rgba(235,236,240,1)",
  neutral40: "rgba(223,225,230,1)",
  neutral50: "rgba(193,199,208,1)",
  neutral600: "rgba(149,158,173,1)",
  neutral700: "rgba(107,119,140,1)",
  neutral800: "rgba(59,71,96,1)",
  neutral900: "rgba(11,29,55,1)",
  black: "rgba(0,0,0,1)",
  white: "rgba(255,255,255,1)",
};

export type ColorType = keyof typeof Colors;
