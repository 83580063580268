// Copyright 2021 Merit International Inc. All Rights Reserved

import { Image } from "react-native";
import { getTestProps } from "../../utils/testProps";
import { useTheme } from "../../theme/useTheme";
import type { ComponentTestProps } from "../../utils/testProps";
import type { IconTheme } from "../../theme/types";
import type { ImageProps, ImageSourcePropType } from "react-native";

type ImagePropsOptionalSource = Omit<ImageProps, "source"> & {
  readonly source?: ImageSourcePropType;
};

export type IconProps = ComponentTestProps &
  ImagePropsOptionalSource & {
    readonly alt?: string;
    readonly name: IconName;
  };

export type IconName = keyof IconTheme;

export function Icon({ alt, name, testID, testProps, testUniqueID, ...rest }: IconProps) {
  const { theme } = useTheme();

  return (
    <Image
      style={{ height: 20, width: 20 }}
      {...rest}
      accessibilityLabel={alt}
      accessible
      source={theme.icons[name]}
      {...getTestProps({ componentName: "Icon", testID, testProps, testUniqueID })}
    />
  );
}
