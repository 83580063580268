// Copyright 2021 Merit International Inc. All Rights Reserved

import { Button as ButtonText } from "../Text";
import { Colors } from "../../theme/colors";
import { Helpers } from "@merit/frontend-utils";
import { Icon } from "../Icon";
import { Pressable, StyleSheet, View } from "react-native";
import { getTestProps } from "../../utils/testProps";
import { useActive, useFocus, useHover } from "react-native-web-hooks";
import { useMemo, useRef, useState } from "react";
import { useTheme } from "../../theme/useTheme";
import type { ComponentTestProps } from "../../utils/testProps";
import type { IconName } from "../Icon";
import type { ReactNode } from "react";
import type { StyleProp, ViewStyle } from "react-native";

const { Some, getPseudoState } = Helpers;

const styles = StyleSheet.create({
  base: {
    alignItems: "center",
    borderWidth: 1,
    display: "flex",
    justifyContent: "center",
  },
  innerWrapper: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
});

export type ButtonSize = "extraSmall" | "large" | "medium" | "small";
export const buttonSizeToHeight: Record<ButtonSize, number> = {
  extraSmall: 24,
  large: 48,
  medium: 40,
  small: 32,
} as const;

export type ButtonProps = ComponentTestProps & {
  readonly accessibilityHint?: string;
  readonly accessibilityLabel?: string; // Overrides testId generation
  readonly customContent?: ReactNode; // Override text for complex buttons
  readonly disabled?: boolean;
  readonly iconLeft?: IconName;
  readonly iconRight?: IconName;
  readonly onPress: () => Promise<void> | void;
  readonly shape?: "circle" | "default" | "square";
  readonly size?: ButtonSize;
  readonly style?: StyleProp<ViewStyle>;
  readonly text?: string;
  readonly type?: "destructive" | "primary" | "secondary";
};

export function Button({
  accessibilityHint,
  accessibilityLabel,
  customContent,
  disabled: userDisabled = false,
  iconLeft,
  iconRight,
  onPress,
  shape = "default",
  size = "medium",
  style,
  testID,
  testProps,
  testUniqueID,
  text,
  type = "primary",
}: ButtonProps) {
  const { theme } = useTheme();
  const [isAwaitingPressHandler, setIsAwaitingPressHandler] = useState(false);

  const disabled = userDisabled || isAwaitingPressHandler;

  const ref = useRef(null);
  const isHovered = useHover(ref);
  const isFocused = useFocus(ref);
  const isActive = useActive(ref);

  const height = useMemo(() => buttonSizeToHeight[size], [size]);

  const textComponent = useMemo(() => {
    const textColor = disabled ? theme.colors.text.disabled : theme.colors.text.actionButton;

    if (text === undefined) {
      return undefined;
    }

    return (
      <View style={{ paddingHorizontal: theme.spacing.s }}>
        <ButtonText color={textColor} size={size}>
          {text}
        </ButtonText>
      </View>
    );
  }, [
    disabled,
    theme.colors.text.disabled,
    theme.colors.text.actionButton,
    theme.spacing.s,
    text,
    size,
  ]);

  const buttonState = useMemo(
    () => getPseudoState(disabled, isActive, isHovered, isFocused),
    [isHovered, isFocused, isActive, disabled]
  );

  // Memoized styles so that we only calculate once, not on every rerender
  const buttonStyles = useMemo(() => {
    // eslint-disable-next-line no-underscore-dangle
    const _buttonStyles: Record<typeof type, Record<typeof buttonState, ViewStyle>> = {
      destructive: {
        active: {
          backgroundColor: Colors.coral100, // TODO: Design hasn't added this color to the palette yet
          borderColor: theme.colors.action.critical.depressed,
        },
        disabled: {
          backgroundColor: theme.colors.action.disabled,
          borderColor: theme.colors.border.disabled,
        },
        focused: {
          backgroundColor: theme.colors.action.disabled,
          borderColor: theme.colors.action.critical.default,
        },
        hovered: {
          backgroundColor: Colors.coral50, // TODO: Design hasn't added this color to the palette yet
          borderColor: theme.colors.action.critical.hovered,
        },
        none: {
          backgroundColor: Colors.coral50, // TODO: Design hasn't added this color to the palette yet
          borderColor: theme.colors.action.critical.default,
        },
      },
      primary: {
        active: {
          backgroundColor: theme.colors.action.pressed,
          borderColor: theme.colors.border.action.pressed,
        },
        disabled: {
          backgroundColor: theme.colors.action.disabled,
          borderColor: theme.colors.border.disabled,
        },
        focused: {
          backgroundColor: theme.colors.action.disabled,
          borderColor: theme.colors.border.action.default,
        },
        hovered: {
          backgroundColor: theme.colors.action.hovered,
          borderColor: theme.colors.border.action.hovered,
        },
        none: {
          backgroundColor: theme.colors.action.default,
          borderColor: theme.colors.border.action.default,
        },
      },
      secondary: {
        active: {
          backgroundColor: theme.colors.action.secondary.pressed,
          borderColor: theme.colors.border.action.pressed,
        },
        disabled: {
          backgroundColor: theme.colors.action.disabled,
          borderColor: theme.colors.border.disabled,
        },
        focused: {
          backgroundColor: theme.colors.action.disabled,
          borderColor: theme.colors.border.action.default,
        },
        hovered: {
          backgroundColor: theme.colors.action.secondary.hovered,
          borderColor: theme.colors.border.action.hovered,
        },
        none: {
          backgroundColor: theme.colors.action.secondary.default,
          borderColor: theme.colors.border.default,
        },
      },
    };

    return _buttonStyles[type][buttonState];
  }, [theme, buttonState, type]);

  const handlePress = async () => {
    setIsAwaitingPressHandler(true);

    try {
      await onPress();
    } finally {
      setIsAwaitingPressHandler(false);
    }
  };

  return (
    <Pressable
      accessibilityHint={accessibilityHint}
      accessibilityLabel={accessibilityLabel}
      accessibilityRole="button"
      disabled={disabled}
      onPress={() => {
        handlePress();
      }}
      ref={ref}
      {...getTestProps({ componentName: "Button", testID, testProps, testUniqueID })}
    >
      <View
        style={[
          styles.base,
          buttonStyles,
          {
            borderRadius: shape === "circle" ? height / 2 : 4,
            height,
            width: shape === "circle" || shape === "square" ? height : undefined,
          },
          style,
        ]}
      >
        {Some(customContent) ? (
          customContent
        ) : (
          <View style={[styles.innerWrapper, shape === "default" && { paddingHorizontal: 24 }]}>
            {Some(iconLeft) ? <Icon alt="" name={iconLeft} /> : <View />}
            {Some(textComponent) && <View>{textComponent}</View>}
            {Some(iconRight) ? <Icon alt="" name={iconRight} /> : <View />}
          </View>
        )}
      </View>
    </Pressable>
  );
}
