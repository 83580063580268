import * as popup from "react-native-walkthrough-tooltip";
import { Body } from "../Text";
import { Helpers } from "@merit/frontend-utils";
import { Icon } from "../Icon";
import { Platform, Pressable, StatusBar, StyleSheet, View } from "react-native";
import { getTestProps } from "../../utils/testProps";
import { useState } from "react";
import { useTheme } from "../../theme";
import type { PropsWithChildren } from "react";
import type { TooltipProps } from "./types";

export const Tooltip: React.FC<PropsWithChildren<TooltipProps>> = ({
  children,
  nativePlacement,
  testID,
  testProps,
  testUniqueID,
}) => {
  const [showTooltip, setShowToolTip] = useState(false);
  const isTextNode = typeof children === "string";
  const { Some } = Helpers;
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    targetElement: {
      width: "100%",
    },
    targetIcon: {
      height: 20,
      width: 20,
    },
    tooltipContainer: {
      backgroundColor: theme.colors.border.highlight.default,
      borderRadius: 4,
      minWidth: 200,
      paddingVertical: 15,
    },
    tooltipContent: {
      color: theme.colors.background.white,
    },
  });

  return (
    <View
      style={{ maxWidth: 20 }}
      {...getTestProps(
        {
          componentName: "Tooltip",
          testID,
          testProps,
          testUniqueID,
        },
        { componentName: "Tooltip" }
      )}
    >
      <popup.default
        backgroundColor="transparent"
        content={
          <View
            style={styles.tooltipContainer}
            {...getTestProps(
              {
                componentName: "TooltipContent",
                testID,
                testProps,
                testUniqueID,
              },
              { componentName: "TooltipContent" }
            )}
          >
            {isTextNode && (
              <Body center color={theme.colors.background.white} size="l">
                {children}
              </Body>
            )}
            {!isTextNode && children}
          </View>
        }
        isVisible={showTooltip}
        onClose={() => {
          setShowToolTip(false);
        }}
        placement={nativePlacement}
        topAdjustment={
          Platform.OS === "android" && Some(StatusBar) && Some(StatusBar.currentHeight)
            ? -StatusBar.currentHeight
            : 0
        }
      >
        <Pressable
          onPress={() => {
            setShowToolTip(prevState => !prevState);
          }}
          style={styles.targetElement}
          {...getTestProps(
            {
              componentName: "TooltipIcon",
              testID,
              testProps,
              testUniqueID,
            },
            { componentName: "TooltipIcon" }
          )}
        >
          <Icon
            alt="Tooltip Information"
            name={showTooltip ? "informationActive" : "informationInactive"}
            style={styles.targetElement}
          />
        </Pressable>
      </popup.default>
    </View>
  );
};
