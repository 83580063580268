// Copyright 2021 Merit International Inc. All Rights Reserved

import { Body } from "../Text";
import { Image, Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import { getTestProps } from "../../utils/testProps";
import { useHover } from "react-native-web-hooks";
import { useRef } from "react";
import { useTheme } from "../../theme/useTheme";
import CheckIcon from "../../assets/icons/check.png";
import type { BodyProps } from "../Text";
import type { ComponentTestProps } from "../../utils/testProps";
import type { RadioOptionType } from "./RadioButtonGroup";

type InternalRadioButtonProps<T> = ComponentTestProps & {
  readonly disabled: boolean;
  readonly item: RadioOptionType<T>;
  readonly onChange: (s: T) => void;
  readonly selected: boolean;
  readonly shape: "checkmark" | "marker";
  readonly size: "medium" | "small";
  readonly hideLabel: boolean;
  readonly labelSize?: "l" | "s";
};

export function InternalRadioButton<T>({
  disabled,
  hideLabel,
  item,
  labelSize,
  onChange,
  selected,
  shape,
  size,
  testID,
  testProps,
  testUniqueID,
}: InternalRadioButtonProps<T>) {
  const { theme } = useTheme();

  const ref = useRef(null);
  const isHovered = useHover(ref);

  const isMarker = shape === "marker";
  const isMedium = size === "medium";

  // smaller radio buttons get smaller labels
  const bodySize: BodyProps["size"] = isMedium ? "l" : "s";

  // size of radio button circle
  const sizePx = isMedium ? 20 : 16;
  // size of selected circle (used for marker style, not checkmark)
  const innerSizePx = isMedium ? theme.spacing.s : 6;

  // offsets to make sure the checkmark is in the center of the radio button circle
  const marginLeftSize = isMarker ? 2 : -1;
  const checkOffset = isMedium ? 4 : 3;

  const disabledColor = theme.colors.border.disabled;
  const defaultColor = theme.colors.border.default;
  const hoveredColor = theme.colors.action.default;

  const checkStyle = {
    backgroundColor: disabled ? disabledColor : hoveredColor,
    borderColor: hoveredColor,
    borderRadius: sizePx,
    height: sizePx,
    marginLeft: marginLeftSize,
    width: sizePx,
  };

  const markerStyle = {
    backgroundColor: disabled ? theme.colors.icon.disabled : theme.colors.icon.actionButton,
    borderRadius: innerSizePx,
    height: innerSizePx,
    width: innerSizePx,
  };

  const maybeHoverBorderColor = isHovered || selected ? hoveredColor : defaultColor;
  const borderColor = disabled ? disabledColor : maybeHoverBorderColor;
  const textColor = disabled ? disabledColor : theme.colors.text.default;

  const selectedStyle = isMarker ? markerStyle : checkStyle;

  const styles = StyleSheet.create({
    circleStyle: {
      alignItems: "center",
      backgroundColor: selected ? hoveredColor : theme.colors.surface.default,
      ...(disabled && { backgroundColor: theme.colors.action.disabled }),
      borderColor,
      borderRadius: sizePx / 2,
      borderWidth: isHovered && !disabled ? 2 : 1,
      height: sizePx,
      justifyContent: "space-around",
      width: sizePx,
    },
    containerStyle: {
      alignItems: "center",
      flexDirection: "row",
      marginTop: 10,
    },
    selectedStyle,
  });

  const selectedElement =
    shape === "marker" ? (
      <View style={styles.selectedStyle} />
    ) : (
      <View style={styles.selectedStyle}>
        <Image
          source={CheckIcon}
          style={{
            height: sizePx * 0.6,
            marginLeft: checkOffset,
            marginTop: checkOffset,
            width: sizePx * 0.6,
          }}
        />
      </View>
    );

  const isWeb = Platform.OS === "web";

  // allow overriding the auto generated accessibility properties with properties in each individual option.
  const hint = isWeb ? item.accessibilityHint ?? "Selects the option" : undefined;
  const label = isWeb ? item.accessibilityLabel ?? item.label : undefined;

  const accessState = {
    checked: selected,
  };

  return (
    <View ref={ref} style={styles.containerStyle}>
      <TouchableOpacity
        accessibilityHint={hint}
        accessibilityLabel={label}
        accessibilityRole="radio"
        accessibilityState={accessState}
        disabled={disabled}
        onPress={() => {
          onChange(item.value);
        }}
        style={styles.circleStyle}
        {...getTestProps(
          { componentName: "RadioButton", testID, testProps, testUniqueID },
          { componentName: "RadioButton" }
        )}
      >
        {selected && selectedElement}
      </TouchableOpacity>
      {!hideLabel && (
        <>
          <View style={{ width: innerSizePx }} />
          <Body color={textColor} size={labelSize ?? bodySize}>
            {item.label}
          </Body>
        </>
      )}
    </View>
  );
}
