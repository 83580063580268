// Copyright 2021 Merit International Inc. All Rights Reserved

import { Alert } from "./Alert";
import { View } from "react-native";
import type { AlertProps } from "./Alert";

export type AlertContainerProps = {
  readonly alertList: readonly AlertProps[];
};

export const AlertContainer = ({ alertList = [] }: AlertContainerProps) => (
  <View
    style={{
      bottom: 40,
      left: 24,
      position: "absolute",
      width: 360,
    }}
  >
    {alertList.map(alert => (
      <Alert key={alert.id} {...alert} />
    ))}
  </View>
);
