import { useEffect, useState } from "react";
import type { SortBy, SortDirection } from "./types";

export function sortTableData<T>(data: readonly T[], currentSorting: SortBy<T>): readonly T[] {
  const sortedData = [...data].sort((a, b) => {
    const val1 = a[currentSorting.key];
    const val2 = b[currentSorting.key];

    if (val1 === val2) {
      return 0;
    }

    return val1 < val2 ? 1 : -1;
  });

  if (currentSorting.direction === "Ascending") {
    return [...sortedData].reverse();
  }

  return sortedData;
}

export function getSortDirection<T>(key: keyof T, currentSorting: SortBy<T>) {
  if (currentSorting.key === key) {
    return (currentSorting.direction === "Ascending" ? "Descending" : "Ascending") as SortDirection;
  }

  return "Ascending";
}

export function useSort<T>(defaultSort: SortBy<T>): readonly [SortBy<T>, (key: keyof T) => void] {
  const [currentSorting, setCurrentSorting] = useState<SortBy<T>>(defaultSort);

  useEffect(() => {
    setCurrentSorting({ direction: defaultSort.direction, key: defaultSort.key });
  }, [defaultSort.key, defaultSort.direction]);

  const handleChangeSort = (key: keyof T) => {
    setCurrentSorting({ direction: getSortDirection(key, currentSorting), key });
  };

  return [currentSorting, handleChangeSort];
}
