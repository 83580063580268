// Copyright 2022 Merit International Inc. All Rights Reserved
import { BaseTheme } from "../base";
import { Colors } from "../colors";
import { Icons } from "./icons";
import { Images } from "./images";
import merge from "lodash.merge";
import type { PartialTheme, Theme } from "../types";

const DarkTheme: Theme = merge<Record<string, never>, Theme, PartialTheme>({}, BaseTheme, {
  colors: {
    action: {
      critical: {
        default: Colors.black,
        depressed: Colors.black,
        disabled: Colors.black,
        hovered: Colors.black,
      },
      default: Colors.black,
      depressed: Colors.black,
      disabled: Colors.black,
      hovered: Colors.black,
      pressed: Colors.black,
      secondary: {
        default: Colors.black,
        depressed: Colors.black,
        disabled: Colors.black,
        hovered: Colors.black,
        pressed: Colors.black,
      },
    },
    background: {
      default: Colors.black,
      white: Colors.black,
    },
    border: {
      action: {
        default: Colors.black,
        disabled: Colors.black,
        hovered: Colors.black,
        pressed: Colors.black,
      },
      critical: {
        default: Colors.black,
        disabled: Colors.black,
        hovered: Colors.black,
        pressed: Colors.black,
        subdued: Colors.black,
      },
      default: Colors.black,
      disabled: Colors.black,
      highlight: {
        default: Colors.black,
        subdued: Colors.black,
      },
      hovered: Colors.black,
      pressed: Colors.black,
      shadow: {
        subdued: Colors.black,
      },
      subdued: Colors.black,
      success: {
        default: Colors.black,
        subdued: Colors.black,
      },
      warning: {
        default: Colors.black,
        subdued: Colors.black,
      },
    },
    icon: {
      actionButton: Colors.black,
      alert: {
        critical: Colors.black,
        highlight: Colors.black,
        success: Colors.black,
      },
      default: Colors.black,
      disabled: Colors.black,
      hovered: Colors.black,
      pressed: Colors.black,
      subdued: Colors.black,
    },
    interactive: {
      critical: {
        default: Colors.black,
        depressed: Colors.black,
        disabled: Colors.black,
        hovered: Colors.black,
      },
      default: Colors.black,
      depressed: Colors.black,
      disabled: Colors.black,
      hovered: Colors.black,
    },
    surface: {
      alert: {
        critical: Colors.black,
        highlight: Colors.black,
        success: Colors.black,
      },
      default: Colors.black,
      depressed: Colors.black,
      disabled: Colors.black,
      hovered: Colors.black,
      pressed: Colors.black,
      subdued: Colors.black,
    },
    text: {
      actionButton: Colors.black,
      alert: {
        critical: Colors.black,
        highlight: Colors.black,
        success: Colors.black,
      },
      default: Colors.black,
      disabled: Colors.black,
      link: Colors.black,
      subdued: Colors.black,
    },
  },
  fonts: {
    normal: "ProximaNova",
  },
  icons: Icons,
  images: Images,
});

export { DarkTheme };
