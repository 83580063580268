// Copyright 2022 Merit International Inc. All Rights Reserved

import { Helpers } from "@merit/frontend-utils";
import { View } from "react-native";
import { getTestProps } from "../../utils/testProps";
import type { TableRowProps, TableRow as TableRowType } from "./types";

const { Some } = Helpers;

export function TableRow<T extends TableRowType>({
  autoWidth,
  columns,
  render,
  row,
  testID,
  testProps,
  testUniqueID,
}: TableRowProps<T>) {
  return (
    <tr
      key={row.id}
      {...getTestProps(
        {
          componentName: "TableRow",
          testID,
          testProps,
          testUniqueID,
        },
        {
          componentName: "TableRow",
          isHTML: true,
        }
      )}
    >
      {columns.map((column, index) => (
        <td
          key={`${row.id}-${column.key}`}
          style={{
            border: 0,
            borderSpacing: 0,
            margin: 0,
            padding: 0,
          }}
        >
          <View
            style={{
              borderBottomColor: "rgba(0,0,0,0.1)",
              borderBottomWidth: 1,
              flex: column.width === "flex" ? 1 : undefined,
              paddingLeft: index === 0 ? 24 : 0,
              paddingRight: index === columns.length ? 24 : 0,
              width: column.width === "auto" ? autoWidth : column.width,
            }}
            {...getTestProps(
              {
                componentName: "TableItem",
                testID,
                testProps: Some(testProps)
                  ? {
                      ...testProps,
                      elementId: `${row.id}`,
                      elementName: `${column.key}${testProps.elementName}`,
                    }
                  : testProps,
                testUniqueID,
              },
              {
                componentName: "TableItem",
              }
            )}
          >
            {render(row, column.key)}
          </View>
        </td>
      ))}
    </tr>
  );
}
