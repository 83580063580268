// Copyright 2021 Merit International Inc. All Rights Reserved

import { Button } from "../Button";
import { Button as ButtonText } from "../Text";
import { Image, View } from "react-native";
import { emptyFunction } from "../../test-utils/emptyFunction";
import { pickComponentTestProps } from "../../utils/testProps";
import meritSmileTransparent from "../../assets/images/merit-smile-transparent.png";
import type { ComponentTestProps } from "../../utils/testProps";

export type LoginWithMeritButtonProps = ComponentTestProps & {
  readonly onPress?: () => void;
};

export const LoginWithMeritButton = ({
  onPress,
  testID,
  testProps,
  testUniqueID,
}: LoginWithMeritButtonProps) => {
  const componentTestProps = pickComponentTestProps({
    testID,
    testProps,
    testUniqueID,
  });

  return (
    <View>
      <Button
        customContent={
          <View
            style={{
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "center",
              paddingHorizontal: 24,
              paddingVertical: 12,
            }}
          >
            <Image accessible source={meritSmileTransparent} style={{ height: 15, width: 45 }} />
            <View style={{ width: 16 }} />
            <View style={{ borderLeftWidth: 1, height: "100%" }} />
            <View style={{ width: 16 }} />
            <ButtonText>Login With Merit</ButtonText>
          </View>
        }
        onPress={onPress ?? emptyFunction}
        size="large"
        type="primary"
        {...componentTestProps}
      />
    </View>
  );
};
