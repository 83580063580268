// Copyright 2022 Merit International Inc. All Rights Reserved

import { StyleSheet, View } from "react-native";
import { getTestProps } from "../../utils/testProps";
import { useTheme } from "../../theme/useTheme";
import React from "react";
import type { ComponentTestProps } from "../../utils/testProps";
import type { ReactNode } from "react";
import type { StyleProp, ViewStyle } from "react-native";

export type CardProps = ComponentTestProps & {
  readonly children?: ReactNode;
  readonly style?: StyleProp<ViewStyle>;
};

export const Card = ({ children, style, testID, testProps, testUniqueID }: CardProps) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    container: {
      ...theme.elevations.depth2,
      backgroundColor: theme.colors.background.white,
      borderRadius: theme.borderRadii.m,
      padding: theme.spacing.s,
    },
  });

  return (
    <View
      style={[styles.container, style]}
      {...getTestProps({ componentName: "Card", testID, testProps, testUniqueID })}
    >
      {children}
    </View>
  );
};
