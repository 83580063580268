import { Helpers } from "@merit/frontend-utils";
import { Icon } from "../Icon";
import { getTestProps } from "../../utils/testProps";
import { usePopper } from "react-popper";
import { useTheme } from "../../theme";
import React, { useState } from "react";
import type { PropsWithChildren } from "react";
import type { TooltipProps } from "./types";

export const Tooltip: React.FC<PropsWithChildren<TooltipProps>> = ({
  children,
  testID,
  testProps,
  testUniqueID,
  webPlacement,
}) => {
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [tooltipElement, setTooltipElement] = useState<HTMLDivElement | null>(null);
  const [isShown, setIsShown] = useState(false);
  const { theme } = useTheme();
  const { Some } = Helpers;
  const { attributes, styles } = usePopper(referenceElement, tooltipElement, {
    modifiers: [{ name: "offset", options: { offset: [0, 10] } }],
    ...(Some(webPlacement) && { placement: webPlacement }),
  });

  const containerStyles = {
    targetButton: { backgroundColor: "transparent", border: "none", padding: 0 },
    targetElement: {
      maxWidth: 20,
      padding: 0,
    },
    targetIcon: {
      height: 20,
      width: 20,
    },
    tooltip: {
      ...styles.popper,
      backgroundColor: theme.colors.border.highlight.default,
      borderRadius: 4,
      color: theme.colors.background.white,
      fontFamily: theme.fonts.normal,
      fontSize: theme.fontSizes.m.fontSize,
      padding: 15,
      zIndex: 999,
    },
  };

  return (
    <div
      style={containerStyles.targetElement}
      {...getTestProps(
        {
          componentName: "Tooltip",
          testID,
          testProps,
          testUniqueID,
        },
        { componentName: "Tooltip", isHTML: true }
      )}
    >
      <button
        onMouseEnter={() => {
          setIsShown(true);
        }}
        onMouseLeave={() => {
          setIsShown(false);
        }}
        ref={ref => {
          setReferenceElement(ref);
        }}
        style={containerStyles.targetButton}
        type="button"
        {...getTestProps(
          {
            componentName: "TooltipIcon",
            testID,
            testProps,
            testUniqueID,
          },
          { componentName: "TooltipIcon", isHTML: true }
        )}
      >
        <Icon
          alt="Tooltip Information"
          name={isShown ? "informationActive" : "informationInactive"}
          style={containerStyles.targetIcon}
        />
      </button>

      {isShown && (
        <div
          ref={ref => {
            setTooltipElement(ref);
          }}
          style={containerStyles.tooltip}
          {...attributes.popper}
          {...getTestProps(
            {
              componentName: "TooltipContent",
              testID,
              testProps,
              testUniqueID,
            },
            { componentName: "TooltipContent", isHTML: true }
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
};
