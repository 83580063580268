import { StyleSheet, View } from "react-native";
import { useTheme } from "../../theme";
import type { StyleProp, ViewStyle } from "react-native";

export type LineSeparatorProps = {
  readonly style?: StyleProp<ViewStyle>;
};

const LineSeparator = ({ style }: LineSeparatorProps) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly separator: ViewStyle;
  }>({
    separator: {
      borderTopColor: theme.colors.border.default,
      borderTopWidth: StyleSheet.hairlineWidth,
    },
  });

  return <View style={[styles.separator, style]} />;
};

export { LineSeparator };
