// Copyright 2021 Merit International Inc. All Rights Reserved

import { Heading } from "../Text";
import { Helpers } from "@merit/frontend-utils";
import { Icon } from "../Icon";
import { KeyboardAvoidingView, Pressable, Modal as RNModal, StyleSheet, View } from "react-native";
import { getTestProps } from "../../utils/testProps";
import { useTheme } from "../../theme/useTheme";
import type { ComponentTestProps } from "../../utils/testProps";
import type { IconName } from "../Icon";
import type { KeyboardAvoidingViewProps, ViewProps, ViewStyle } from "react-native";
import type { ReactNode } from "react";

const { Some } = Helpers;

type NonKeyboardAvoidingOptions = {
  readonly isKeyboardAvoiding: false;
  readonly props: ViewProps;
};

type KeyboardAvoidingOptions = {
  readonly isKeyboardAvoiding: true;
  readonly props: KeyboardAvoidingViewProps;
};

export type ModalProps = ComponentTestProps & {
  readonly children?: ReactNode;
  readonly contentContainerOptions?: KeyboardAvoidingOptions | NonKeyboardAvoidingOptions;
  readonly maxWidth?: number;
  readonly onClose: () => void;
  readonly title?: string;
  readonly titleIconName?: IconName;
  readonly width?: ViewStyle["width"];
};

export const Modal = ({
  children,
  contentContainerOptions,
  maxWidth,
  onClose,
  testID,
  testProps,
  testUniqueID,
  title,
  titleIconName,
  width,
}: ModalProps) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    backgroundShade: {
      alignItems: "center",
      backgroundColor: "rgba(59, 71, 96, 0.7)", // TODO: neutral800, but transparency isn't themed yet
      flex: 1,
      justifyContent: "center",
      padding: theme.spacing.m,
    },
    container: {
      ...theme.elevations.depth5,
      backgroundColor: theme.colors.surface.default,
      borderRadius: 4,
      maxWidth: maxWidth ?? undefined,
      paddingHorizontal: 32,
      paddingVertical: 24,
      width,
    },
    headerRow: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: theme.spacing.l,
    },
    iconContainer: {
      paddingRight: 16,
    },
  });

  const headingTestProps = Some(testProps)
    ? { ...testProps, elementName: `${testProps.elementName}ModalTitle` }
    : testProps;

  const closeButtonTestProps = Some(testProps)
    ? { ...testProps, elementName: `${testProps.elementName}ModalCloseButton` }
    : testProps;

  const ModalContentComponent =
    contentContainerOptions?.isKeyboardAvoiding === false ? View : KeyboardAvoidingView;

  return (
    <RNModal
      transparent
      {...getTestProps(
        { componentName: "Modal", testID, testProps, testUniqueID },
        { componentName: "Modal" }
      )}
    >
      <ModalContentComponent
        style={styles.backgroundShade}
        {...contentContainerOptions?.props}
        {...getTestProps(
          { componentName: "ModalContent", testID, testProps, testUniqueID },
          { componentName: "ModalContent" }
        )}
      >
        <View style={styles.container}>
          <View style={styles.headerRow}>
            <View style={{ flexDirection: "row" }}>
              {Some(titleIconName) && (
                <View style={styles.iconContainer}>
                  <Icon name={titleIconName} />
                </View>
              )}
              <Heading level="4" testProps={headingTestProps}>
                {title}
              </Heading>
            </View>
            <Pressable onPress={onClose}>
              <Icon name="closeSmallDefault" testProps={closeButtonTestProps} />
            </Pressable>
          </View>
          {children}
        </View>
      </ModalContentComponent>
    </RNModal>
  );
};
