function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

const computed = (create, compute) => (set, get, api) => {
  const setWithComputed = (update, replace) => {
    set(state => {
      const updated = typeof update === 'function' ? update(state) : update;
      const computedState = compute(_extends({}, state, updated));
      return _extends({}, updated, computedState);
    }, replace);
  };

  api.setState = setWithComputed;
  const state = create(setWithComputed, get, api);
  return _extends({}, state, compute(state));
};

export { computed };
