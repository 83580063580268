// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body } from "../Text/Body/Body";
import { StyleSheet, View } from "react-native";
import { useTheme } from "../../theme/useTheme";
import type { MenuItemSectionLabel } from "./types";
import type { StyleProp, TextStyle, ViewStyle } from "react-native";

type MenuItemProps = {
  readonly menuItem: MenuItemSectionLabel;
  readonly style?: StyleProp<ViewStyle>;
};

export const DropdownMenuSectionLabel = ({ menuItem, style }: MenuItemProps) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly text: TextStyle;
  }>({
    container: {
      alignItems: "center",
      flexDirection: "row",
      paddingHorizontal: theme.spacing.l,
      paddingVertical: theme.spacing.s,
    },
    text: {
      ...theme.fontSizes.s,
      color: theme.colors.text.subdued,
      flex: 1,
    },
  });

  return (
    <View key={menuItem.id} style={[styles.container, style]}>
      <Body style={[styles.text]}>{menuItem.text}</Body>
    </View>
  );
};
