/* eslint-disable sort-keys-fix/sort-keys-fix */

// see also: https://docs.microsoft.com/en-us/typography/opentype/spec/os2#usweightclass
export const FontWeights = {
  normal: "400",
  semiBold: "600",
} as const;

const LineHeights = {
  s: 21 as const,
  m: 24 as const,
  l: 28 as const,
  xl: 32 as const,
};

const FontSizes = {
  s: 13 as const,
  m: 16 as const,
  l: 20 as const,
  xl: 24 as const,
};

export const FontSizeLineHeightPairs = {
  s: {
    lineHeight: LineHeights.s,
    fontSize: FontSizes.s,
  },
  m: {
    lineHeight: LineHeights.m,
    fontSize: FontSizes.m,
  },
  l: {
    lineHeight: LineHeights.l,
    fontSize: FontSizes.l,
  },
  xl: {
    lineHeight: LineHeights.xl,
    fontSize: FontSizes.xl,
  },
} as const;

export const Spacing = {
  xxs: 2,
  xs: 4,
  s: 8,
  m: 12,
  l: 16,
  xl: 20,
  xxl: 24,
} as const;

export const BorderRadii = {
  s: 4,
  m: 8,
  l: 12,
  xl: 16,
} as const;
