// Copyright 2022 Merit International Inc. All Rights Reserved

import { Body, Heading } from "../Text";
import { Helpers } from "@merit/frontend-utils";
import { Pressable, StyleSheet, View } from "react-native";
import { getTestProps, pickComponentTestProps } from "../../utils/testProps";
import type { TableHeadingCommonProps } from "./types";

const { Some } = Helpers;

const styles = StyleSheet.create({
  headerItem: { alignItems: "center", flex: 1, flexDirection: "row" },
});

export function TableHeading<T>({
  autoWidth,
  column,
  currentSorting,
  onPressHeader,
  testID,
  testProps,
  testUniqueID,
}: TableHeadingCommonProps<T>) {
  const { sortable = true } = column;

  return (
    <Pressable
      disabled={!sortable}
      key={column.key}
      onPress={() => {
        onPressHeader(column.key as keyof T);
      }}
    >
      <View
        key={column.key}
        style={[
          styles.headerItem,
          column.width === "flex"
            ? {
                flex: 1,
              }
            : {
                width: column.width === "auto" ? autoWidth : column.width,
              },
        ]}
        {...getTestProps(
          {
            componentName: "TableHeading",
            testID,
            testProps: Some(testProps) ? { ...testProps, elementId: `${column.key}` } : testProps,
            testUniqueID,
          },
          {
            componentName: "TableHeading",
          }
        )}
      >
        <Heading level="4" numberOfLines={1}>
          {column.title}
        </Heading>

        {Some(column.rightElement) && column.rightElement}
        {currentSorting.key === column.key && (
          <View style={{ paddingHorizontal: 12 }}>
            {currentSorting.direction === "Ascending" ? (
              <Body
                {...pickComponentTestProps({
                  testID,
                  testProps: Some(testProps)
                    ? {
                        ...testProps,
                        elementName: `${testProps.elementName}TableHeaderSortIconUp`,
                      }
                    : testProps,
                  testUniqueID,
                })}
              >
                {"\u2191"}
              </Body>
            ) : (
              <Body
                {...pickComponentTestProps({
                  testID,
                  testProps: Some(testProps)
                    ? {
                        ...testProps,
                        elementName: `${testProps.elementName}TableHeaderSortIconDown`,
                      }
                    : testProps,
                  testUniqueID,
                })}
              >
                {"\u2193"}
              </Body>
            )}
          </View>
        )}
      </View>
    </Pressable>
  );
}
