// Copyright 2021 Merit International Inc. All Rights Reserved

import { BaseText, resolveTextStyle } from "../Base";
import { Platform, StyleSheet } from "react-native";
import { pickComponentTestProps } from "../../../utils/testProps";
import { useTheme } from "../../../theme/useTheme";
import type { BaseTextProps } from "../Base";

export type HeadingProps = BaseTextProps & {
  readonly level: "1" | "2" | "3" | "4" | "5" | "6";
  readonly bold?: boolean;
};

export const Heading = ({
  bold = false,
  children,
  level,
  style,
  testID,
  testProps,
  testUniqueID,
  ...rest
}: HeadingProps) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    "1": { fontSize: 28, fontWeight: theme.fontWeights.semiBold, lineHeight: 32 },
    "2": {
      fontSize: 26,
      fontWeight: bold ? theme.fontWeights.semiBold : theme.fontWeights.normal,
      lineHeight: 32,
    },
    "3": {
      fontSize: 20,
      fontWeight: bold ? theme.fontWeights.semiBold : theme.fontWeights.normal,
      lineHeight: 24,
    },
    "4": { fontSize: 16, fontWeight: theme.fontWeights.semiBold, lineHeight: 24 }, // If h4, h5, h6, just use h3 styles
    "5": { fontSize: 14, fontWeight: theme.fontWeights.semiBold, lineHeight: 20 }, // If h4, h5, h6, just use h3 styles
    "6": { fontSize: 12, fontWeight: theme.fontWeights.normal, lineHeight: 16 }, // If h4, h5, h6, just use h3 styles
  });

  const componentTestProps = pickComponentTestProps({
    testID,
    testProps,
    testUniqueID,
  });

  return (
    <BaseText
      {...Platform.select({
        web: {
          accessibilityLevel: level, // This lets react-native-web choose h1, h2, h3, etc.
          accessibilityRole: "header",
        },
      })}
      aria-level={level}
      style={resolveTextStyle(theme.fonts.normal, [styles[level], style])}
      {...componentTestProps}
      {...rest}
    >
      {children}
    </BaseText>
  );
};
