// Copyright 2021 Merit International Inc. All Rights Reserved

import { Button as ButtonText } from "../Text";
import { Errors, Helpers } from "@merit/frontend-utils";
import { Icon } from "../Icon";
import { Image, Platform, Pressable, StyleSheet, View } from "react-native";
import { getTestProps } from "../../utils/testProps";
import { useActive, useFocus, useHover } from "react-native-web-hooks";
import { useTheme } from "../../theme/useTheme";
import React, { useMemo, useRef } from "react";
import defaultOrg from "../../assets/images/org-logo.png";
import type { ComponentTestProps } from "../../utils/testProps";

const { getPseudoState } = Helpers;
const { UnreachableCaseError } = Errors;
// Placeholder until this is generated
type Org = {
  readonly id: number;
  readonly logo?: string;
  readonly name: string;
};

export type OrgSelectButtonProps = ComponentTestProps & {
  readonly accessibilityHint?: string;
  readonly accessibilityLabel?: string;
  readonly onPress: () => void;
  readonly org: Org;
};

export const OrgSelectButton = ({
  accessibilityHint,
  accessibilityLabel,
  onPress,
  org,
  testID,
  testProps,
  testUniqueID,
}: OrgSelectButtonProps) => {
  const { theme } = useTheme();
  const ref = useRef(null);
  const isHovered = useHover(ref);
  const isFocused = useFocus(ref);
  const isActive = useActive(ref);
  const buttonState = useMemo(
    () => getPseudoState(false, isActive, isHovered, isFocused),
    [isHovered, isFocused, isActive]
  );

  // TODO: All colors need to be represented in the theme
  const calculatedStyles = useMemo(() => {
    switch (buttonState) {
      case "active": {
        return Platform.select({
          android: {
            backgroundColor: theme.colors.surface.depressed,
          },
          ios: { backgroundColor: theme.colors.surface.depressed },
          web: {
            backgroundColor: theme.colors.surface.depressed,
            boxShadow: `0px 0px 0px 2px ${theme.colors.action.hovered}, 0px 0px 0px 6px #DEF6F5`,
          },
        });
      }

      case "hovered": {
        return Platform.select({
          web: {
            backgroundColor: theme.colors.surface.hovered,
            boxShadow: `0px 0px 0px 2px ${theme.colors.action.hovered}, 0px 0px 0px 6px #DEF6F5`,
          },
        });
      }

      case "focused": {
        return Platform.select({
          web: {
            backgroundColor: theme.colors.surface.hovered,
            boxShadow: `0px 0px 0px 2px ${theme.colors.action.hovered}, 0px 0px 0px 6px #DEF6F5`,
          },
        });
      }

      case "none": {
        return Platform.select({
          android: {
            backgroundColor: theme.colors.surface.default,
          },
          ios: {
            backgroundColor: theme.colors.surface.default,
          },
          web: {
            backgroundColor: theme.colors.surface.default,
            boxShadow: "none",
          },
        });
      }

      case "disabled":
        throw new Error("Somehow got disabled OrgSelectButton");

      default:
        throw new UnreachableCaseError(buttonState);
    }
  }, [
    buttonState,
    theme.colors.action.hovered,
    theme.colors.surface.default,
    theme.colors.surface.depressed,
    theme.colors.surface.hovered,
  ]);

  const styles = StyleSheet.create({
    button: {
      ...calculatedStyles,
      alignItems: "center",
      borderColor: theme.colors.border.default,
      borderRadius: 4,
      borderWidth: 1,
      flexDirection: "row",
      height: 64,
      justifyContent: "space-between",
      paddingHorizontal: 16,
      paddingVertical: 12,
    },
  });

  return (
    <Pressable
      accessibilityHint={accessibilityHint}
      accessibilityLabel={accessibilityLabel}
      onPress={onPress}
      {...getTestProps({ componentName: "OrgSelectButton", testID, testProps, testUniqueID })}
      ref={ref}
    >
      <View style={styles.button}>
        <Image source={org.logo ?? defaultOrg} style={{ height: 40, width: 40 }} />
        <View style={{ flex: 1, paddingLeft: 16 }}>
          <ButtonText>{org.name}</ButtonText>
        </View>
        <Icon name="arrowForwardMediumAction" />
      </View>
    </Pressable>
  );
};
