// Copyright 2022 Merit International Inc. All Rights Reserved

import { Body, Heading } from "../Text";
import { Helpers } from "@merit/frontend-utils";
import { Pressable, StyleSheet, View } from "react-native";
import { getTestProps, pickComponentTestProps } from "../../utils/testProps";
import type { TableHeadingWebProps } from "./types";

const { Some } = Helpers;

const styles = StyleSheet.create({
  headerItem: {
    alignItems: "center",
    borderBottomColor: "rgba(0,0,0,0.1)",
    borderBottomWidth: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingVertical: 16,
  },
});

export function TableHeading<T>({
  autoWidth,
  column,
  currentSorting,
  isFirst,
  isLast,
  onPressHeader,
  testID,
  testProps,
  testUniqueID,
}: TableHeadingWebProps<T>) {
  const { sortable = true } = column;

  return (
    <th key={column.key} scope="col" style={{ border: 0, borderSpacing: 0, margin: 0, padding: 0 }}>
      <Pressable
        disabled={!sortable}
        onPress={() => {
          onPressHeader(column.key as keyof T);
        }}
        style={[
          styles.headerItem,
          {
            flex: column.width === "flex" ? 1 : undefined,
            paddingLeft: isFirst ? 24 : 0,
            paddingRight: isLast ? 24 : 0,
            width: column.width === "auto" ? autoWidth : column.width,
          },
        ]}
        {...getTestProps(
          {
            componentName: "TableHeading",
            testID,
            testProps: Some(testProps) ? { ...testProps, elementId: `${column.key}` } : testProps,
            testUniqueID,
          },
          { componentName: "TableHeading" }
        )}
      >
        <Heading level="4" numberOfLines={1}>
          {column.title}
        </Heading>

        {Some(column.rightElement) && column.rightElement}
        {currentSorting.key === column.key && (
          <View style={{ paddingHorizontal: 12 }}>
            {currentSorting.direction === "Ascending" ? (
              <Body
                {...pickComponentTestProps({
                  testID,
                  testProps: Some(testProps)
                    ? { ...testProps, elementName: `${testProps.elementName}TableHeaderSortIconUp` }
                    : testProps,
                  testUniqueID,
                })}
              >
                {"\u2191"}
              </Body>
            ) : (
              <Body
                {...pickComponentTestProps({
                  testID,
                  testProps: Some(testProps)
                    ? {
                        ...testProps,
                        elementName: `${testProps.elementName}TableHeaderSortIconDown`,
                      }
                    : testProps,
                  testUniqueID,
                })}
              >
                {"\u2193"}
              </Body>
            )}
          </View>
        )}
      </Pressable>
    </th>
  );
}
