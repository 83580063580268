import { createContext } from "react";
import type { ComponentTestProps } from "../../../utils/testProps";
import type { FCWithChildren } from "../../types";
import type { TextProps } from "react-native";

export type TextContextType = Omit<TextProps, keyof ComponentTestProps | "style">;

const TextContext = createContext<TextContextType>({});

const TextContextProvider: FCWithChildren<TextContextType> = ({ children, ...rest }) => (
  <TextContext.Provider value={rest}>{children}</TextContext.Provider>
);

export { TextContextProvider, TextContext };
