/* eslint-disable functional/immutable-data */
import { useCallback, useEffect } from "react";
import { useTheme } from "../../theme";

// these are the only functions we "care" about
type AcsbJS = {
  readonly init: (arg0: unknown) => void;
  readonly destroy: () => void;
};

type FEWindow = Window & {
  readonly acsbJS: AcsbJS;
};

const AccessibilityWidget = () => {
  const { theme } = useTheme();
  const h = document.querySelector("head") ?? document.body;

  const initWidget = useCallback(() => {
    const s = document.createElement("script");

    s.src = "https://acsbapp.com/apps/app/dist/js/app.js";
    s.async = true;
    s.onload = () => {
      const acsbJS = (window as unknown as FEWindow).acsbJS;
      acsbJS.init({
        disableBgProcess: false,
        footerHtml: "",
        hideMobile: false,
        hideTrigger: false,
        language: "en",
        leadColor: theme.colors.brand.turquiose,
        mobile: {
          triggerOffsetX: 10,
          triggerOffsetY: 10,
          triggerPositionX: "right",
          triggerPositionY: "bottom",
          triggerRadius: "20",
          triggerSize: "small",
        },
        position: "right",
        statementLink: "",
        triggerColor: theme.colors.brand.turquiose,
        triggerIcon: "people",
        triggerOffsetX: 20,
        triggerOffsetY: 20,
        triggerPositionX: "right",
        triggerPositionY: "bottom",
        triggerRadius: "50%",
        triggerSize: "bottom",
      });
    };
    h.appendChild(s);
  }, [h, theme.colors.brand.turquiose]);

  useEffect(() => {
    initWidget();

    return () => {
      (window as unknown as FEWindow).acsbJS.destroy();
    };
  }, [initWidget]);

  return null;
};

export { AccessibilityWidget };
