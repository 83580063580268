// Copyright 2021 Merit International Inc. All Rights Reserved

/*
  Base text component which implements all of the shared functionality for
  all of the Text components.  Should never be used directly, so this will
  not be exported from the Text/index.ts.
*/

import { Helpers } from "@merit/frontend-utils";
import { Platform, Text as RNText, StyleSheet } from "react-native";
import { TextContext } from "./TextContext/TextContext";
import { getTestProps } from "../../utils/testProps";
import { useContext } from "react";
import type { ComponentTestProps } from "../../utils/testProps";
import type { StyleProp, TextProps, TextStyle } from "react-native";

const { Some } = Helpers;

const propStyles = StyleSheet.create({
  capitalize: {
    textTransform: "capitalize",
  },
  center: {
    textAlign: "center",
  },
  uppercase: {
    textTransform: "uppercase",
  },
});

export type BaseTextProps = ComponentTestProps &
  TextProps & {
    readonly capitalize?: boolean;
    readonly center?: boolean;
    readonly color?: string;
    readonly uppercase?: boolean;
  };

const getFontWeightName = (fontWeightValue: TextStyle["fontWeight"]) => {
  if (fontWeightValue === "600") {
    return "SemiBold";
  }

  return "";
};

// see PE-229 for the motivation behind this function revolving around iOS fontWeight/Style and custom fonts
export const resolveTextStyle = (
  baseFontFamily: string,
  style: StyleProp<TextStyle> | undefined
): TextStyle | undefined => {
  if (style === undefined) {
    return style;
  }
  const flatStyle = StyleSheet.flatten(style); // need to flatten() to deal with composed styles, e.g. [style1, style2]
  const { fontWeight } = flatStyle;
  const fontWeightName = getFontWeightName(fontWeight);

  const getFontFamily = () => {
    const fontFamily = `${baseFontFamily}${fontWeightName}`; // e.g. ProximaNova, ProximaNovaSemiBold
    if (Platform.OS === "web") {
      return `${fontFamily}, Helvetica Neue, Helvetica, Arial, sans-serif`;
    }

    return fontFamily;
  };

  const resolvedFontFamily = getFontFamily();

  // weight and style have no effect on iOS when using custom fonts, but android and web can still affect it so we omit it here
  const filteredEntries = Object.entries(flatStyle).filter(
    ([key]) => !["fontWeight", "fontStyle"].includes(key)
  );
  const resolvedStyleEntries = [...filteredEntries, ["fontFamily", resolvedFontFamily]];

  return Object.fromEntries(resolvedStyleEntries) as TextStyle;
};

export const BaseText = ({
  capitalize,
  center,
  children,
  color,
  style,
  testID,
  testProps,
  testUniqueID,
  uppercase,
  ...rest
}: BaseTextProps) => {
  const contextProps = useContext(TextContext);

  return (
    <RNText
      {...contextProps}
      {...rest}
      style={[
        Some(color) && { color },
        center === true && propStyles.center,
        capitalize === true && propStyles.capitalize,
        uppercase === true && propStyles.uppercase,
        style,
      ]}
      {...getTestProps({ componentName: "Text", testID, testProps, testUniqueID })}
    >
      {children}
    </RNText>
  );
};
