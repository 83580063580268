// Copyright 2021 Merit International Inc. All Rights Reserved

import { Heading } from "../Text";
import { Helpers } from "@merit/frontend-utils";
import { Image, StyleSheet, View } from "react-native";
import { emptyFunction } from "../../test-utils/emptyFunction";
import { getTestProps } from "../../utils/testProps";
import { usePopper } from "react-popper";
import { useState } from "react";
import { useTheme } from "../../theme";
import type { FCWithChildren } from "../types";
import type { PopoverProps } from "./types";

const { Some } = Helpers;

export const Popover: FCWithChildren<PopoverProps> = ({
  children,
  content,
  imageSource,
  onToggle = emptyFunction,
  title,
  testID,
  testProps,
  testUniqueID,
  webPlacement,
}) => {
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popoverElement, setPopoverElement] = useState<HTMLDivElement | null>(null);
  const [isShown, setIsShown] = useState(false);
  const { theme } = useTheme();
  const { attributes, styles } = usePopper(referenceElement, popoverElement, {
    modifiers: [{ name: "offset", options: { offset: [0, 10] } }],
    ...(Some(webPlacement) && { placement: webPlacement }),
  });

  const handleToggle = () => {
    onToggle(!isShown);
    setIsShown(prev => !prev);
  };

  const containerStyles = {
    body: {
      lineHeight: "24px",
      minWidth: 200,
      padding: "12px 16px",
    },
    container: {
      ...styles.popper,
      ...theme.elevations.depth5,
      backgroundColor: theme.colors.background.white,
      borderRadius: 4,
      color: theme.colors.text.actionButton,
      fontSize: theme.fontSizes.m.fontSize,
      zIndex: 999,
    },
    targetButton: { backgroundColor: "transparent", border: "none", padding: 0 },
    targetElement: {
      maxWidth: 20,
      padding: 0,
    },
    title: {
      borderBottom: `solid 1px ${theme.colors.border.subdued}`,
      padding: "8px 16px",
    },
  };

  const nativeStyles = StyleSheet.create({
    content: {
      paddingHorizontal: 16,
      paddingVertical: 12,
    },
    image: {
      height: 164,
      width: "100%",
    },
  });

  return (
    <View
      style={containerStyles.targetElement}
      {...getTestProps({
        componentName: "Popover",
        testID,
        testProps,
        testUniqueID,
      })}
    >
      <button
        onClick={handleToggle}
        ref={ref => {
          setReferenceElement(ref);
        }}
        style={containerStyles.targetButton}
        type="button"
      >
        {children}
      </button>

      {isShown && (
        <div
          ref={ref => {
            setPopoverElement(ref);
          }}
          style={containerStyles.container}
          {...attributes.popper}
        >
          <View>
            {Some(imageSource) && <Image source={imageSource} style={nativeStyles.image} />}
            {Some(title) && (
              <div style={containerStyles.title}>
                <Heading level="4">{title}</Heading>
              </div>
            )}
            <View style={nativeStyles.content}>{content}</View>
          </View>
        </div>
      )}
    </View>
  );
};
