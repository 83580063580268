// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Heading } from "../Text";
import { Helpers } from "@merit/frontend-utils";
import { Image, Pressable, StyleSheet, View } from "react-native";
import { getTestProps } from "../../utils/testProps";
import { useTheme } from "../../theme/useTheme";
import type { ComponentTestProps } from "../../utils/testProps";
import type { ImageSourcePropType } from "react-native";
import type { ReactNode } from "react";

const { Some } = Helpers;

const styles = StyleSheet.create({
  close: {
    alignItems: "center",
    justifyContent: "flex-start",
  },
  closeButton: {
    height: 15,
    width: 15,
  },
  container: {
    borderRadius: 4,
    marginTop: 10,
  },
  contentWrapper: {
    flex: 1,
    flexDirection: "row",
  },
  icon: {
    height: "100%",
    width: "100%",
  },
  iconWrapper: {
    height: 15,
    marginRight: 10,
    width: 15,
  },
  mainWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 18,
    paddingVertical: 12,
  },
});

type BorderType = { readonly [icon in AlertOptions]: string };
type AlertIconsType = { readonly [icon in AlertOptions]: ImageSourcePropType };
type AlertOptions = "error" | "info" | "success" | "warning";

export type AlertProps = ComponentTestProps & {
  readonly closable?: boolean;
  readonly customContent?: ReactNode;
  readonly id: string;
  readonly onPressDelete: (id: string) => void;
  readonly size?: "medium" | "small";
  readonly text?: string;
  readonly title?: string;
  readonly type: AlertOptions;
};

export const Alert = ({
  closable,
  customContent,
  id,
  onPressDelete,
  size,
  testID,
  testProps,
  testUniqueID,
  text,
  title,
  type,
}: AlertProps) => {
  const { theme } = useTheme();

  const AlertIcons: AlertIconsType = {
    error: theme.icons.warningMediumCritical,
    info: theme.icons.informationMediumHighlight,
    success: theme.icons.successMediumSuccess,
    warning: theme.icons.errorMediumWarning,
  };
  const borderColor: BorderType = {
    error: theme.colors.border.critical.default,
    info: theme.colors.border.highlight.default,
    success: theme.colors.border.success.default,
    warning: theme.colors.border.warning.default,
  };

  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: theme.colors.surface.default,
          borderTopColor: borderColor[type],
          borderTopWidth: theme.spacing.xs,
          shadowColor: theme.colors.border.action.hovered,
          width: size === "small" ? 280 : 360,
          ...theme.elevations.depth3,
        },
      ]}
      {...getTestProps(
        {
          componentName: "Alert",
          testID,
          testProps,
          testUniqueID,
        },
        { componentName: "Alert" }
      )}
    >
      <View style={styles.mainWrapper}>
        <View
          style={[styles.contentWrapper]}
          {...getTestProps(
            {
              componentName: "AlertContent",
              testID,
              testProps,
              testUniqueID,
            },
            { componentName: "AlertContent" }
          )}
        >
          <View style={[styles.iconWrapper, Boolean(title) ? { marginTop: 6 } : null]}>
            <Image source={AlertIcons[type]} style={styles.icon} />
          </View>
          {Some(customContent) ? (
            customContent
          ) : (
            <View style={{ flex: 1, flexDirection: "column" }}>
              {Boolean(title) && (
                <View style={{ marginBottom: 8 }}>
                  <Heading level="4">{title}</Heading>
                </View>
              )}
              {Boolean(text) && <Body size="l">{text}</Body>}
            </View>
          )}
        </View>
        {Boolean(closable) && (
          <View style={[styles.close, Boolean(title) ? { marginTop: 6 } : null]}>
            <Pressable
              onPress={() => {
                onPressDelete(id);
              }}
              {...getTestProps(
                {
                  componentName: "AlertCloseButton",
                  testID,
                  testProps,
                  testUniqueID,
                },
                { componentName: "AlertCloseButton" }
              )}
            >
              <Image source={theme.icons.closeSmallDefault} style={styles.closeButton} />
            </Pressable>
          </View>
        )}
      </View>
    </View>
  );
};
