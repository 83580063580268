// Copyright 2022 Merit International Inc. All Rights Reserved
import CancelCircleLarge from "../assets/icons/cancel_circle_large.png";
import CancelLarge from "../assets/icons/cancel_large.png";
import CancelSquareLarge from "../assets/icons/cancel_square_large.png";
import ProximaNova from "../assets/fonts/ProximaNova.otf";
import ProximaNovaSemiBold from "../assets/fonts/ProximaNovaSemiBold.otf";

export const CommonImages = {
  cancel_circle_large: CancelCircleLarge,
  cancel_large: CancelLarge,
  cancel_square_large: CancelSquareLarge,
};

export const Fonts = {
  ProximaNova,
  ProximaNovaSemiBold,
};
