// Copyright 2022 Merit International Inc. All Rights Reserved

import { Heading } from "../../components/Text";
import { Icon } from "../../components/Icon";
import { Pressable, StyleSheet, View } from "react-native";
import { getTestProps } from "../../utils/testProps";
import { useTheme } from "../../theme/useTheme";
import type { ComponentTestProps } from "../../utils/testProps";

export type TagProps = ComponentTestProps & {
  readonly onPressClose: () => void;
  readonly text: string;
};

export const Tag = ({ onPressClose, testID, testProps, testUniqueID, text }: TagProps) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    bodyContainer: {
      paddingHorizontal: theme.spacing.s,
    },
    container: {
      alignItems: "center",
      alignSelf: "flex-start",
      backgroundColor: theme.colors.background.white,
      borderColor: theme.colors.border.default,
      borderRadius: theme.borderRadii.s,
      borderWidth: 1,
      flexDirection: "row",
      marginHorizontal: theme.spacing.xs,
      paddingHorizontal: theme.spacing.xs,
    },
    iconContainer: {
      paddingVertical: theme.spacing.xs,
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.bodyContainer}>
        <Heading level="6">{text}</Heading>
      </View>
      <View style={styles.iconContainer}>
        <Pressable
          accessibilityRole="button"
          onPress={onPressClose}
          {...getTestProps({
            componentName: "Tag",
            testID,
            testProps,
            testUniqueID,
          })}
        >
          <Icon name="closeSmallDefault" />
        </Pressable>
      </View>
    </View>
  );
};
