// Copyright 2022 Merit International Inc. All Rights Reserved

import { DarkTheme } from "./dark";
import { LightTheme } from "./light";
import type { Theme } from "./types";

export const themes: Record<string, Theme> = {
  DARK: DarkTheme,
  LIGHT: LightTheme,
};

export type ThemeName = keyof typeof themes;

// Runtime type checker for theme names
export const themeNameIsValid = (name: string): name is ThemeName => name in themes;
