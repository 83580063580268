// Copyright 2021 Merit International Inc. All Rights Reserved

import { BorderRadii, FontSizeLineHeightPairs, FontWeights, Spacing } from "../constants";
import { Colors } from "../colors";
import { CommonImages } from "../common";
import { Icons } from "./icons";
import { Images } from "./images";
import { Platform } from "react-native";
import type { Theme } from "../types";

const BaseTheme: Theme = {
  borderRadii: { ...BorderRadii },
  colors: {
    action: {
      critical: {
        default: Colors.coral500,
        depressed: Colors.coral900,
        disabled: Colors.neutral10,
        hovered: Colors.coral800,
      },
      default: Colors.turqoise300,
      depressed: Colors.midnight800,
      disabled: Colors.neutral10,
      hovered: Colors.turqoise400,
      pressed: Colors.turqoise200,
      secondary: {
        default: Colors.white,
        depressed: Colors.neutral800,
        disabled: Colors.neutral10,
        hovered: Colors.neutral40,
        pressed: Colors.neutral30,
      },
      selected: Colors.turqoise50,
    },
    background: {
      default: Colors.neutral20,
      white: Colors.white,
    },
    border: {
      action: {
        default: Colors.turqoise700,
        disabled: Colors.neutral40,
        hovered: Colors.black,
        pressed: Colors.black,
      },
      critical: {
        default: Colors.coral500,
        disabled: Colors.coral100,
        hovered: Colors.coral800,
        pressed: Colors.coral900,
        subdued: Colors.coral200,
      },
      default: Colors.neutral50,
      disabled: Colors.neutral40,
      highlight: {
        default: Colors.oceanBlue800,
        subdued: Colors.oceanBlue300,
      },
      hovered: Colors.neutral600,
      pressed: Colors.neutral800,
      shadow: {
        subdued: Colors.neutral30,
      },
      subdued: Colors.neutral40,
      success: {
        default: Colors.jade400,
        subdued: Colors.jade100,
      },
      warning: {
        default: Colors.citrine500,
        subdued: Colors.citrine200,
      },
    },
    brand: {
      citrine: Colors.citrine500,
      coral: Colors.coral500,
      jade: Colors.jade400,
      midnight: Colors.midnight800,
      oceanBlue: Colors.oceanBlue800,
      turquiose: Colors.turqoise300,
    },
    icon: {
      actionButton: Colors.black,
      alert: {
        critical: Colors.coral500,
        highlight: Colors.oceanBlue800,
        success: Colors.jade400,
      },
      default: Colors.neutral800,
      disabled: Colors.neutral50,
      hovered: Colors.neutral900,
      pressed: Colors.midnight800,
      subdued: Colors.neutral700,
    },
    interactive: {
      critical: {
        default: Colors.coral500,
        depressed: Colors.coral900,
        disabled: Colors.coral200,
        hovered: Colors.coral800,
      },
      default: Colors.oceanBlue800,
      depressed: Colors.midnight800,
      disabled: Colors.neutral30,
      hovered: Colors.oceanBlue900,
    },
    surface: {
      alert: {
        critical: Colors.coral500,
        highlight: Colors.oceanBlue800,
        success: Colors.jade400,
      },
      default: Colors.white,
      depressed: Colors.neutral40,
      disabled: Colors.neutral10,
      hovered: Colors.neutral20,
      pressed: Colors.neutral30,
      subdued: Colors.neutral10,
    },
    text: {
      actionButton: Colors.black,
      alert: {
        critical: Colors.coral800,
        highlight: Colors.oceanBlue800,
        success: Colors.jade700,
      },
      default: Colors.neutral900,
      disabled: Colors.neutral40,
      link: Colors.oceanBlue800,
      secondary: Colors.white,
      subdued: Colors.neutral700,
    },
  },
  elevations: {
    depth1: Platform.select({
      android: {
        elevation: 1,
      },
      ios: {
        shadowColor: Colors.black,
        shadowOffset: {
          height: 1,
          width: 0,
        },
        shadowOpacity: 0.05,
        shadowRadius: 0,
      },
      web: {
        boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.05)",
      },
    }),
    depth2: Platform.select({
      android: {
        elevation: 2,
      },
      ios: {
        shadowColor: Colors.black,
        shadowOffset: {
          height: 0,
          width: 0,
        },
        shadowOpacity: 0.25,
        shadowRadius: 1,
      },
      web: {
        boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 1px 1px rgba(0, 0, 0, 0.05)",
      },
    }),
    depth3: Platform.select({
      android: {
        elevation: 4,
      },
      ios: {
        shadowColor: Colors.black,
        shadowOffset: {
          height: 0,
          width: 0,
        },
        shadowOpacity: 0.2,
        shadowRadius: 2,
      },
      web: {
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1)",
      },
    }),
    depth4: Platform.select({
      android: {
        elevation: 8,
      },
      ios: {
        shadowColor: Colors.black,
        shadowOffset: {
          height: 0,
          width: 0,
        },
        shadowOpacity: 0.1,
        shadowRadius: 3,
      },
      web: {
        boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.1), 0px 4px 20px rgba(0, 0, 0, 0.15)",
      },
    }),
    depth5: Platform.select({
      android: {
        elevation: 16,
      },
      ios: {
        shadowColor: Colors.black,
        shadowOffset: {
          height: 0,
          width: 0,
        },
        shadowOpacity: 0.1,
        shadowRadius: 4,
      },
      web: {
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1), 0px 8px 40px rgba(0, 0, 0, 0.2)",
      },
    }),
    inset1: Platform.select({
      android: {},
      ios: {},
      web: {
        boxShadow: "inset 0px 2px 0px rgba(0, 0, 0, 0.05)",
      },
    }),
  },
  fontSizes: { ...FontSizeLineHeightPairs },
  fontWeights: { ...FontWeights },
  fonts: {
    normal: "ProximaNova",
  },
  icons: { ...Icons },
  images: { ...CommonImages, ...Images },
  spacing: { ...Spacing },
};

export { BaseTheme };
