// Copyright 2021 Merit International Inc. All Rights Reserved

import { Animated, Platform, Pressable, StyleSheet, View } from "react-native";
import { Heading } from "../Text";
import { Helpers } from "@merit/frontend-utils";
import { Icon } from "../Icon";
import { getTestProps } from "../../utils/testProps";
import { useActive, useFocus, useHover } from "react-native-web-hooks";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "../../theme/useTheme";
import type { ComponentTestProps } from "../../utils/testProps";

const { getPseudoState } = Helpers;

export type SwitchProps = ComponentTestProps & {
  readonly accessibilityHint?: string;
  readonly accessibilityLabel: string;
  readonly disabled?: boolean;
  readonly onToggle: (val: boolean) => void;
  readonly showIcon?: boolean;
  readonly showLabel?: boolean;
  readonly size?: "large" | "medium";
  readonly value: boolean;
};

export function Switch({
  accessibilityHint,
  accessibilityLabel,
  disabled = false,
  onToggle,
  showIcon = false,
  showLabel = false,
  size = "medium",
  testID,
  testProps,
  testUniqueID,
  value = false,
}: SwitchProps) {
  const { theme } = useTheme();
  const [toggled, setToggled] = useState(value);

  const ref = useRef(null);
  const isHovered = useHover(ref);
  const isFocused = useFocus(ref);
  const isActive = useActive(ref);
  const switchState = useMemo(
    () => getPseudoState(disabled, isActive, isHovered, isFocused),
    [isHovered, isFocused, isActive, disabled]
  );

  // Constants to drive styling throughout the component
  const padding = 2;
  const isMedium = size === "medium";
  const circleSize = isMedium ? 16 : 20;
  const ovalSize = isMedium ? 20 : 24;
  const switchSize = isMedium ? 40 : 48;

  // Animation settings to move the switch toggle
  const animationWidth = switchSize - circleSize - padding * (!disabled && isActive ? 4 : 2);
  const animateCircle = useRef(new Animated.Value(toggled ? animationWidth : 0)).current;

  useEffect(() => {
    Animated.timing(animateCircle, {
      duration: 100,
      toValue: toggled ? animationWidth : 0,
      useNativeDriver: true,
    }).start();
  }, [animateCircle, animationWidth, isActive, size, toggled]);

  useEffect(() => {
    setToggled(value);
  }, [value]);

  function handlePress() {
    setToggled(!toggled);
    onToggle(!toggled);
  }

  // Apply styling based on focus states
  // Memoized styles so that we only calculate once, not on every rerender
  const calculatedStyles = useMemo(() => {
    switch (switchState) {
      case "disabled": {
        return {
          container: {
            backgroundColor: theme.colors.action.secondary.hovered,
          },
          outerContainer: {
            backgroundColor: "transparent",
          },
        };
      }

      case "active": {
        return {
          container: {},
          outerContainer: {
            backgroundColor: theme.colors.action.hovered,
            shadowColor: "rgba(222,246,245,1)",
            shadowRadius: 6,
          },
        };
      }

      case "hovered": {
        return {
          container: {},
          outerContainer: {
            backgroundColor: theme.colors.action.hovered,
            outlineWidth: 0,
            shadowColor: theme.colors.action.hovered,
            shadowRadius: 2,
          },
        };
      }

      case "focused": {
        return {
          container: {},
          outerContainer: {
            backgroundColor: theme.colors.action.hovered,
            outlineWidth: 0,
            shadowColor: theme.colors.action.default,
            shadowRadius: 6,
          },
        };
      }

      case "none": {
        return {
          container: {},
          outerContainer: {},
        };
      }

      default:
        throw new Error("Invalid Switch State");
    }
  }, [theme, switchState]);

  const styles = StyleSheet.create({
    circle: {
      backgroundColor: "white",
      borderRadius: circleSize / 2,
      elevation: 1,
      height: circleSize,
      width: circleSize,
      zIndex: 1,
    },
    container: {
      backgroundColor: toggled
        ? theme.colors.action.default
        : theme.colors.action.secondary.pressed,
      borderRadius: 16,
      height: switchSize / 2,
      justifyContent: "center",
      padding,
      width: switchSize,
    },
    iconLeft: {
      left: 9,
      position: "absolute",
      top: isMedium ? 7 : 9,
    },
    iconRight: {
      position: "absolute",
      right: 9,
      top: isMedium ? 7 : 9,
    },
    label: {
      color: disabled ? theme.colors.icon.disabled : theme.colors.icon.actionButton,
      fontSize: 12,
      lineHeight: 16,
    },
    labelLeft: {
      left: 8,
      position: "absolute",
    },
    labelRight: {
      position: "absolute",
      right: 8,
    },
    outerContainer: {
      alignItems: "center",
      backgroundColor: "transparent",
      borderRadius: 16,
      height: switchSize / 2 + padding * 2,
      justifyContent: "center",
      width: switchSize + padding * 2,
    },
    oval: {
      backgroundColor: "white",
      borderRadius: 16,
      height: ovalSize - 4,
      position: "absolute",
      right: toggled ? padding : undefined,
      width: ovalSize,
    },
  });

  return (
    <View
      ref={ref}
      style={[
        styles.outerContainer,
        calculatedStyles.outerContainer,
        // @ts-expect-error Cursor only exists on web, typing is missing
        Platform.OS === "web" && { cursor: "pointer" },
      ]}
    >
      <View style={[styles.container, calculatedStyles.container]}>
        {/* Oval is a seperate element to simplify circle animation */}
        {!disabled && isActive && <View style={styles.oval} />}

        {showIcon && (
          <>
            <View style={styles.iconLeft}>
              <Icon alt="" name={disabled ? "switchMediumDefault" : "successMediumSuccess"} />
            </View>

            <View style={styles.iconRight}>
              <Icon alt="" name={disabled ? "switchMediumDefault" : "successMediumSuccess"} />
            </View>
          </>
        )}

        {showLabel && (
          <>
            <View style={styles.labelLeft}>
              <Heading level="6">Y</Heading>
            </View>

            <View style={styles.labelRight}>
              <Heading level="6">N</Heading>
            </View>
          </>
        )}

        {/* Pressable has to be at end of View to avoid onPress conflicting with absolute positioning */}
        <Pressable
          accessibilityHint={accessibilityHint}
          accessibilityLabel={accessibilityLabel}
          accessibilityRole="switch"
          accessibilityState={{ checked: toggled }}
          disabled={disabled}
          onPress={handlePress}
          style={
            Platform.OS === "web" && {
              // @ts-expect-error Typing is missing but property is valid
              outlineColor: theme.colors.action.default,
              outlineOffset: 5,
            }
          }
          {...getTestProps({
            componentName: "Switch",
            testID,
            testProps,
            testUniqueID,
          })}
        >
          <Animated.View style={[styles.circle, { transform: [{ translateX: animateCircle }] }]} />
        </Pressable>
      </View>
    </View>
  );
}
