import { Helpers } from "@merit/frontend-utils";
import type { AllTestProps } from "./types";

/**
 * Given an object containing a mix of new and old key-value pairs for the test prop generator functions,
 * this function returns an object containing prop-value pairs for the data-testid.
 * Intended to be used as an isolation layer of sorts for gracefully transitioning from the old
 * way we generate test-id props using generate[Div]TestProps() to the new way using generateTestIdProps().
 * This function accepts arguments for both the new and old ways of generating test-id related props.
 * It will prioritize using `testProps` (new way) if both are supplied.
 * @see {@link https://github.com/merit/frontend-utils/pull/30 more info} regarding the gnarly typing
 * @see {@link https://github.com/merit/frontend-components/pull/115#discussion_r1146782312 GitHub comment} explaining why `componentName` is "duplicated"
 * @example `reconcileTestProps({ testProps: {screenName: "Derp", elementName: "Herp"}, testID: "Old" })` yields `{testID: "Herp:Derp"}`
 * @example `reconcileTestProps({ componentName: "Component", testID: "Old" })` yields `{testID: "Old:Component"}`
 * @example `reconcileTestProps({ testProps: {screenName: "Derp", elementName: "Herp"}}, { isHTML: true })` yields `{"data-testid": "Herp:Derp"}`
 */
export function getTestProps(
  { componentName = "", testID, testProps, testUniqueID }: AllTestProps,
  options?: Helpers.HTMLTestIdPropsOptions | Helpers.RNTestIdPropsOptions
): Helpers.HTMLTestIdProps | Helpers.RNTestIdProps {
  if (testProps !== undefined) {
    return Helpers.generateTestIdProps(testProps, options);
  }
  if (options?.isHTML === true) {
    return Helpers.generateDivTestProps({ componentName, testID, uniqueID: testUniqueID });
  }

  return Helpers.generateTestProps({ componentName, testID, uniqueID: testUniqueID });
}
