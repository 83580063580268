// Copyright 2021 Merit International Inc. All Rights Reserved

import { Body } from "../Text";
import { Helpers } from "@merit/frontend-utils";
import { Icon } from "../Icon";
import { TextInput as RNTextInput, StyleSheet, Text, View } from "react-native";
import { getTestProps } from "../../utils/testProps";
import { useActive, useFocus, useHover } from "react-native-web-hooks";
import { useTheme } from "../../theme/useTheme";
import React, { useMemo, useRef } from "react";
import type { Size, TextInputProps } from "./types";

const { Some, getPseudoState, mergeRefs } = Helpers;

const inputHeight: Record<Size, number> = {
  large: 48,
  medium: 40,
  small: 32,
};

export const TextInput = React.forwardRef<RNTextInput, TextInputProps>(
  (
    {
      autoFocus,
      disabled,
      fontSize,
      keyboardType,
      label,
      labelRightElement,
      leftIcon,
      maxLength,
      numberOfLines = 1,
      onBlur,
      onChange,
      onChangeText,
      onFocus,
      onKeyPress,
      placeholder,
      secureTextEntry,
      showErrorIcon,
      size = "medium",
      testID,
      testProps,
      testUniqueID,
      textAlign = "left",
      value,
      width,
    },
    ref
  ) => {
    const { theme } = useTheme();

    const currentRef = useRef(null);

    const isHovered = useHover(currentRef);
    const isFocused = useFocus(currentRef);
    const isActive = useActive(currentRef);
    const inputState = useMemo(
      () => getPseudoState(disabled ?? false, isActive, isHovered, isFocused),
      [isHovered, isFocused, isActive, disabled]
    );

    const styles = StyleSheet.create({
      container: {
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "flex-start",
        zIndex: 1,
      },
      disabled: {
        borderColor: "rgba(223,225,230,1)",
        color: "rgba(193,199,208,1)",
      },
      errorIconContainer: {
        position: "absolute",
        right: theme.spacing.m,
      },
      errorIconPlaceholder: {
        alignItems: "center",
        backgroundColor: "rgba(107,119,140,1)",
        borderRadius: 10,
        color: "white",
        fontSize: 12,
        fontWeight: "800",
        height: 20,
        justifyContent: "center",
        padding: theme.spacing.xxs,
        textAlign: "center",
        width: 20,
      },
      input: {
        ...(fontSize === undefined ? {} : theme.fontSizes[fontSize]), // TODO: we dont have a theme.fontSize that uses the body medium of size 14
        backgroundColor: theme.colors.surface.default,
        borderColor: "rgba(193,199,208,1)",
        borderRadius: 4,
        borderWidth: 1,
        flex: 1,
        height: numberOfLines > 1 ? "auto" : inputHeight[size],
        maxHeight: numberOfLines * theme.fontSizes[fontSize ?? "m"].lineHeight,
        paddingHorizontal: 12,
        paddingVertical: numberOfLines > 1 ? 8 : 0,
      },
      inputActive: {
        borderColor: theme.colors.border.action.pressed,
      },
      inputDisabled: {
        borderColor: theme.colors.border.default,
        opacity: 0.4,
      },
      inputFocused: {
        borderColor: theme.colors.action.hovered,
      },
      inputHovered: {
        borderColor: theme.colors.action.hovered,
      },
      inputNone: {
        borderColor: theme.colors.border.default,
      },
      leftIconContainer: {
        height: "100%",
        justifyContent: "center",
        left: theme.spacing.s,
        position: "absolute",
      },
    });

    const inputPseudoStateStyles = useMemo(() => {
      switch (inputState) {
        case "disabled": {
          return styles.inputDisabled;
        }
        case "active": {
          return styles.inputActive;
        }

        case "hovered": {
          return styles.inputHovered;
        }
        case "focused": {
          return styles.inputFocused;
        }
        case "none": {
          return styles.inputNone;
        }
        default:
          throw new Error("Invalid TextInput State");
      }
    }, [
      inputState,
      styles.inputActive,
      styles.inputDisabled,
      styles.inputFocused,
      styles.inputHovered,
      styles.inputNone,
    ]);

    return (
      <View>
        {Some(label) && (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBottom: 4,
            }}
          >
            <Body>{label}</Body>
            {Some(labelRightElement) && <View>{labelRightElement}</View>}
          </View>
        )}

        <View style={[styles.container, width === undefined ? {} : { width }]}>
          <RNTextInput
            autoFocus={autoFocus}
            editable={!(disabled ?? false)}
            keyboardType={keyboardType}
            maxLength={maxLength}
            multiline={numberOfLines > 1}
            numberOfLines={numberOfLines}
            onBlur={onBlur}
            onChange={onChange}
            onChangeText={onChangeText}
            onFocus={onFocus}
            onKeyPress={onKeyPress}
            placeholder={placeholder}
            placeholderTextColor={disabled ?? false ? "rgba(193,199,208,1)" : "rgba(107,119,140,1)"}
            ref={mergeRefs([currentRef, ref])}
            secureTextEntry={secureTextEntry}
            style={[
              styles.input,
              (disabled ?? false) && styles.disabled,
              Boolean(leftIcon) && { paddingLeft: 32 },
              inputPseudoStateStyles,
              width === undefined ? {} : { width },
            ]}
            textAlign={textAlign}
            underlineColorAndroid="transparent"
            value={value}
            {...getTestProps({ componentName: "TextInput", testID, testProps, testUniqueID })}
          />
          {Some(leftIcon) && (
            <View style={styles.leftIconContainer}>
              <Icon name={leftIcon} />
            </View>
          )}
          <View style={styles.errorIconContainer}>
            {(showErrorIcon ?? false) && (
              // TODO (PE-138): borderRadius on text doesn't work on iOS, either wrap in a View to apply the borderRadius on, or use a real icon
              <Text
                style={[
                  styles.errorIconPlaceholder,
                  (disabled ?? false) && { backgroundColor: "rgba(193,199,208,1)" },
                ]}
              >
                !
              </Text>
            )}
          </View>
        </View>
      </View>
    );
  }
);

export type { TextInputProps } from "./types";
