// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body } from "../Text";
import { Icon } from "../Icon";
import { StyleSheet, View } from "react-native";
import { getTestProps } from "../../utils/testProps";
import { useMemo } from "react";
import { useTheme } from "../../theme";
import type { ComponentTestProps } from "../../utils/testProps";
import type { IconName } from "../Icon";
import type { ImageStyle, TextStyle, ViewStyle } from "react-native";

export const BADGE_COLORS = [
  "citrine",
  "coral",
  "jade",
  "oceanBlue",
  "turquoise",
  "white",
] as const;

type BadgeColor = typeof BADGE_COLORS[number];

export type BadgeProps = ComponentTestProps & {
  readonly color?: BadgeColor;
  readonly iconLeft?: IconName;
  readonly iconRight?: IconName;
  readonly text: string;
};

const Badge = ({
  color = "turquoise",
  iconLeft,
  iconRight,
  testID,
  testProps,
  testUniqueID,
  text,
}: BadgeProps) => {
  const { theme } = useTheme();

  const colorPair = useMemo(() => {
    const colorMap: Record<BadgeColor, { readonly background: string; readonly text: string }> = {
      citrine: {
        background: theme.colors.brand.citrine,
        text: theme.colors.text.default,
      },
      coral: {
        background: theme.colors.brand.coral,
        text: theme.colors.text.default,
      },
      jade: {
        background: theme.colors.brand.jade,
        text: theme.colors.text.secondary,
      },
      oceanBlue: {
        background: theme.colors.brand.oceanBlue,
        text: theme.colors.text.secondary,
      },
      turquoise: {
        background: theme.colors.brand.turquiose,
        text: theme.colors.text.default,
      },
      white: {
        background: theme.colors.background.white,
        text: theme.colors.text.default,
      },
    };

    return colorMap[color];
  }, [theme, color]);

  const styles = StyleSheet.create<{
    readonly badgeWrapper: ViewStyle;
    readonly badgeText: TextStyle;
    readonly iconLeft: ImageStyle;
    readonly iconRight: ImageStyle;
    readonly icon: ImageStyle;
  }>({
    badgeText: {
      color: colorPair.text,
    },
    badgeWrapper: {
      alignSelf: "flex-start",
      backgroundColor: colorPair.background,
      borderRadius: 20,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      paddingHorizontal: 9,
      paddingVertical: 5,
    },
    icon: {
      alignSelf: "center",
      height: 12,
      width: 12,
    },
    iconLeft: {
      marginRight: theme.spacing.xs,
    },
    iconRight: {
      marginLeft: theme.spacing.xs,
    },
  });

  return (
    <View
      style={styles.badgeWrapper}
      {...getTestProps({ componentName: "Badge", testID, testProps, testUniqueID })}
    >
      {iconLeft === undefined ? null : (
        <Icon name={iconLeft} style={StyleSheet.compose(styles.icon, styles.iconLeft)} />
      )}
      <Body style={styles.badgeText}>{text}</Body>
      {iconRight === undefined ? null : (
        <Icon name={iconRight} style={StyleSheet.compose(styles.icon, styles.iconRight)} />
      )}
    </View>
  );
};

export { Badge };
