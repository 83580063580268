// Copyright 2021 Merit International Inc. All Rights Reserved

import { StyleSheet, View } from "react-native";
import { getTestProps } from "../../utils/testProps";
import type { ComponentTestProps } from "../../utils/testProps";
import type { StyleProp, ViewStyle } from "react-native";

/*
  This is a pure CSS Chevron icon that should be easier to work
  with than an image in most cases, since it enables us to change
  colors and size dynamically.

  TODO: The translateX and translateY vars should be derived from size.
*/

const styles = StyleSheet.create({
  chevron: {
    borderColor: "#a1a1a1",
  },
  chevronDown: {
    transform: [{ translateY: -2 }, { rotate: "135deg" }],
  },
  chevronUp: {
    transform: [{ translateY: 4 }, { rotate: "-45deg" }],
  },
});

export type ChevronProps = ComponentTestProps & {
  readonly color: string;
  readonly direction: "down" | "up";
  readonly size: number;
  readonly customStyles?: StyleProp<ViewStyle>;
};

export const Chevron = ({
  color,
  customStyles,
  direction,
  size,
  testID,
  testProps,
  testUniqueID,
}: ChevronProps) => (
  <View
    style={[
      styles.chevron,
      direction === "down" ? styles.chevronDown : styles.chevronUp,
      {
        borderRightWidth: size / 10,
        borderTopWidth: size / 10,
        height: size,
        width: size,
      },
      { borderColor: color },
      customStyles,
    ]}
    {...getTestProps({ componentName: "Chevron", testID, testProps, testUniqueID })}
  />
);
