import type { IconName } from "../Icon";

export enum MenuItemKind {
  Pressable = "pressable",
  SectionLabel = "sectionLabel",
}

export type MenuItemCommon = {
  readonly id: string;
  readonly menuItemKind: MenuItemKind;
};

export type MenuItemPressable = MenuItemCommon & {
  readonly disabled?: boolean;
  readonly iconLeft?: IconName;
  readonly iconRight?: IconName;
  readonly menuItemKind: MenuItemKind.Pressable;
  readonly onPress: () => void;
  readonly pressableKind?: "default" | "destructive";
  readonly text: string;
};

export type MenuItemSectionLabel = MenuItemCommon & {
  readonly menuItemKind: MenuItemKind.SectionLabel;
  readonly text: string;
};

export type MenuItem = MenuItemPressable | MenuItemSectionLabel;
