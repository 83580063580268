// Copyright 2021 Merit International Inc. All Rights Reserved

import { Body, Button as ButtonText } from "../Text";
import { Chevron } from "../Chevron";
import { Colors } from "../../theme/colors";
import { Helpers } from "@merit/frontend-utils";
import { Icon } from "../Icon";
import { Modal, Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import { Picker } from "@react-native-picker/picker";
import { emptyFunction } from "../../test-utils/emptyFunction";
import { getTestProps } from "../../utils/testProps";
import { useState } from "react";
import type { Option, SelectProps } from "./types";

const { Some } = Helpers;

const styles = StyleSheet.create({
  base: {
    alignItems: "center",
    borderColor: Colors.neutral600,
    borderRadius: 5,
    borderWidth: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 12,
    paddingVertical: 10,
    zIndex: 1,
  },
  labelContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 4,
    zIndex: 2,
  },
  picker: {
    backgroundColor: "#d0d4da",
  },
  pickerAndroid: {
    // This style overrides defaults to make sure that the component renders and
    // is pressable, but doesn't interfere with our component or styles.
    color: "transparent",
    height: "100%",
    opacity: 0,
    position: "absolute",
    width: "100%",
  },
  pickerHeader: {
    alignItems: "center",
    backgroundColor: "#f8f8f8",
    borderTopColor: "#dedede",
    borderTopWidth: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
});

export const Select = ({
  disabled = false,
  helpText,
  label,
  onSelectOption = emptyFunction,
  options,
  placeholder = { label: "Choose an option…", value: "" },
  rightElement,
  testID,
  testProps,
  testUniqueID,
}: SelectProps) => {
  const [selectedOption, setSelectedOption] = useState<Option>(options[0]);
  const [pickerOpen, setPickerOpen] = useState<boolean>(false);

  const optionsWithPlaceholder = [placeholder].concat(options);

  const togglePicker = () => {
    setPickerOpen(!pickerOpen);
  };

  const handleSelectOption = (itemIndex: number) => {
    setSelectedOption(optionsWithPlaceholder[itemIndex]);
    onSelectOption(optionsWithPlaceholder[itemIndex]);
  };

  const BaseComponent = () => (
    <>
      <View style={styles.labelContainer}>
        <Body>{label}</Body>
        {Some(rightElement) && <View>{rightElement}</View>}
        {Boolean(helpText) && (
          <TouchableOpacity onPress={emptyFunction}>
            <Icon
              alt="tooltip"
              name="informationOutlinedMediumDefault"
              {...getTestProps(
                { componentName: "SelectTooltip", testID, testProps, testUniqueID },
                { componentName: "SelectTooltip" }
              )}
            />
          </TouchableOpacity>
        )}
      </View>
      <TouchableOpacity
        activeOpacity={0.5}
        onPress={() => {
          if (!disabled) {
            togglePicker();
          }
        }}
        {...getTestProps(
          { componentName: "Select", testID, testProps, testUniqueID },
          { componentName: "Select" }
        )}
      >
        <View style={styles.base}>
          <Body>{selectedOption.label}</Body>
          <Chevron color={Colors.neutral600} direction="down" size={10} />
        </View>
      </TouchableOpacity>
    </>
  );

  const renderIOSModalPicker = () => (
    <>
      <BaseComponent />
      <Modal
        animationType="slide"
        supportedOrientations={["portrait"]}
        transparent
        visible={pickerOpen}
        {...getTestProps(
          { componentName: "IOSModalPicker", testID, testProps, testUniqueID },
          { componentName: "IOSModalPicker" }
        )}
      >
        <TouchableOpacity
          onPress={() => {
            togglePicker();
          }}
          style={{ flex: 1 }}
        />
        <View style={styles.pickerHeader}>
          <View />
          <TouchableOpacity
            onPress={togglePicker}
            {...getTestProps(
              { componentName: "PickerIOSDoneButton", testID, testProps, testUniqueID },
              { componentName: "PickerIOSDoneButton" }
            )}
          >
            <ButtonText color="#007aff">Done</ButtonText>
          </TouchableOpacity>
        </View>
        <View style={styles.picker}>
          <Picker
            onValueChange={(_, itemIndex) => {
              handleSelectOption(itemIndex);
            }}
            selectedValue={selectedOption.value}
            {...getTestProps(
              { componentName: "PickerIOSModal", testID, testProps, testUniqueID },
              { componentName: "PickerIOSModal" }
            )}
          >
            {optionsWithPlaceholder.map(item => (
              <Picker.Item
                key={item.value}
                label={item.label}
                value={item.value}
                {...getTestProps(
                  {
                    componentName: "PickerIOSItem",
                    testID: item.testID,
                    testProps: item.testProps,
                    testUniqueID: item.testUniqueID,
                  },
                  { componentName: "PickerIOSItem" }
                )}
              />
            ))}
          </Picker>
        </View>
      </Modal>
    </>
  );

  const renderAndroidDropdownPicker = () => (
    <>
      <BaseComponent />
      <Picker
        mode="dropdown"
        onValueChange={(_, itemIndex) => {
          handleSelectOption(itemIndex);
        }}
        selectedValue={selectedOption.value}
        style={styles.pickerAndroid}
        {...getTestProps(
          { componentName: "PickerAndroid", testID, testProps, testUniqueID },
          { componentName: "PickerAndroid" }
        )}
      >
        {optionsWithPlaceholder.map(item => (
          <Picker.Item
            key={item.value}
            label={item.label}
            value={item.value}
            {...getTestProps(
              {
                componentName: "PickerAndroidItem",
                testID: item.testID,
                testProps: item.testProps,
                testUniqueID: item.testUniqueID,
              },
              { componentName: "PickerAndroidItem" }
            )}
          />
        ))}
      </Picker>
    </>
  );

  return Platform.OS === "ios" ? renderIOSModalPicker() : renderAndroidDropdownPicker();
};
