import type {
  AllComponentTestProps,
  DeprecatedComponentTestProps,
  NewComponentTestProps,
} from "./types";

/**
 * Used for components that accept both new and old test-related props, and also renders
 * other components that do the same. Since components that properly include `ComponentTestProps`
 * as part of its props, the typing enforces XOR between new or old props, but not both. This
 * function serves to pick only the necessary props to satisfy that typing constraint.
 * Prioritizes `testProps` (new way) if present.
 * @see {@link https://github.com/merit/frontend-components/pull/115#discussion_r1146761535 GitHub comment} for further context
 */
export function pickComponentTestProps(
  props: AllComponentTestProps
): DeprecatedComponentTestProps | NewComponentTestProps {
  const { testID, testProps, testUniqueID } = props;
  if (testProps === undefined) {
    return { testID, testUniqueID };
  }

  return { testProps };
}
